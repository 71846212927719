export const social_links= [
    {
        company:'facebook', 
        link: 'https://www.facebook.com'
    }, 
    {
        company:'twitter',
        link: 'https://www.twitter.com'
    }, 
    {
        company:'instagram',
        link: 'https://instagram.com'
    }, 
    {
        company:'youtube',
        link: 'https://youtube.com'
    }, 
    {
        company:'linkedin',
        link: 'https://www.linkedin.com'
    }
];