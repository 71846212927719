import { Text } from "../info/Text";
import { LayoutPadding } from "../utilities";
import { Flex, Text as TextBase } from "rebass";

import { colors } from "../../styleguide/theme";

export const FullWidthContent = ({
	title,
	subTitle,
	paragraph1,
	paragraph2,
}) => (
	<Flex bg={colors.lightblue} width={"100%"} color={colors.white}>
		<LayoutPadding py={[9, 9, 11]}>
			<Text as="h2" mb={subTitle ? [5] : [8]}>
				{title}
			</Text>
			{subTitle && (
				<Text as="h3" mb={[8]}>
					{subTitle}
				</Text>
			)}
			<Flex width={"100%"} flexDirection={["column", "column", "row"]}>
				<Flex
					width={paragraph2 ? ["100%", "100%", "50%"] : ["100%"]}
					pr={paragraph2 ? ["0", "0", 10] : "0"}
					flexDirection="column"
					dangerouslySetInnerHTML={{ __html: paragraph1 }}
				>
					{/* <TextBase
						as="p"
						fontSize={[1, 1, 2]}
						fontWeight={100}
						css={`
							line-height: 22px;
						`}
						
					/> */}
				</Flex>
				{paragraph2 && (
					<Flex
						width={["100%", "100%", "50%"]}
						pl={["0", "0", 8]}
						flexDirection="column"
						dangerouslySetInnerHTML={{ __html: paragraph2 }}
					></Flex>
				)}
			</Flex>
		</LayoutPadding>
	</Flex>
);
