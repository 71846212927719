import React from "react";
import { Text as TextBase, Image, Flex, Box } from "rebass";
import { v1 as uuidv1 } from "uuid";
import { colors } from "../../styleguide/theme";

import { Text } from "../info/Text";
import { Video } from "../media";
import { LayoutPadding } from "../utilities";
import { SplitContent } from "../fragments";
import { DonationTracker } from "../trackers/Donation";
import {
	FullWidthContent,
	ParagraphSection,
	SubsectionText,
} from "../streamBlocks";
import { ButtonLink } from "../links";
import Link from "react-scroll/modules/components/Link";

const DonationBar = ({ raised, donations, goal, ...rest }) => (
	<Flex
		bg={colors.white}
		width={["100%", "100%", "50%"]}
		alignItems={"center"}
		{...rest}
	>
		<LayoutPadding py={[9, 9, 11]}>
			<DonationTracker
				pt={"0"}
				mb={"0"}
				raised={raised}
				donations={donations}
				goal={goal}
			/>
		</LayoutPadding>
	</Flex>
);

const dev = process.env.NODE_ENV === "development";

export const RenderStreamField = ({ body, noGap }) => {
	return body.reduce((acc, field, index) => {
		const { value } = field;
		const type = field.type || field.blockType;

		switch (type) {
			case "image":
				return [
					...acc,
					<Flex key={uuidv1()} mb={10} px={["0", "0", "64px"]}>
						<Image src={field.image && field.image.rendition.url} />
					</Flex>,
				];
			case "video":
				return [
					...acc,
					<Flex
						key={uuidv1()}
						px={["0", "0", "64px"]}
						mb={noGap ? "0" : 10}
					>
						<Video small src={value} />
					</Flex>,
				];
			case "paragraph":
				return [
					...acc,
					<LayoutPadding key={uuidv1()}>
						<TextBase
							as="div"
							key={index}
							dangerouslySetInnerHTML={{ __html: value }}
							fontSize={[1]}
							fontWeight={100}
							mb={6}
							css={`
								line-height: 22px;
							`}
						/>
					</LayoutPadding>,
				];
			case "heading":
				return [
					...acc,
					<LayoutPadding key={uuidv1()}>
						<Text key={index} as="h2" mb={8}>
							{value}
						</Text>
					</LayoutPadding>,
				];
			case "quote":
				return [
					...acc,
					<LayoutPadding key={uuidv1()}>
						<Box
							width={["100%"]}
							px={[2, 2, 4, 4]}
							py={[6, 6, 8, 8]}
							css={`
								float: right;
								position: relative;
							`}
						>
							<Image
								width={"8rem"}
								src="/static/icons/misc/Quotation.svg"
								css={`
									position: absolute;
									z-index: -1;
									top: 10px;
									left: 0px;
								`}
							/>
							<Text
								as="p"
								fontWeight={"bold"}
								ml={12}
								fontSize={[3]}
								styling={`
                                    font-style: italic;
                                `}
							>
								{value}
							</Text>
						</Box>
					</LayoutPadding>,
				];

			case "section_block_one":
				return [
					...acc,
					<SplitContent
						key={uuidv1()}
						bg={colors[field.backgroundColor] || colors.purple}
						color={
							(field.backgroundColor === "offwhite" &&
								colors.black) ||
							colors.white
						}
						split={[
							["100%", "100%", "100%", "60%"],
							["100%", "100%", "100%", "40%"],
						]}
						// Inputs
						title={field.title}
						paragraph={field.paragraph}
						image={field.image && field.image.rendition.url}
					/>,
				];
			case "section_block_two":
				return [
					...acc,
					<SplitContent
						key={uuidv1()}
						bg={colors[field.backgroundColor] || colors.purple}
						color={
							(field.backgroundColor === "offwhite" &&
								colors.black) ||
							colors.white
						}
						split={[
							["100%", "100%", "100%", "60%"],
							["100%", "100%", "100%", "40%"],
						]}
						flexDirection={[
							"column-reverse",
							"column-reverse",
							"row-reverse",
						]}
						// Inputs
						title={field.title}
						paragraph={field.paragraph}
						image={field.image && field.image.rendition.url}
					/>,
				];
			case "section_block_three":
				// 50 50 Image / Text
				return [
					...acc,
					<SplitContent
						key={uuidv1()}
						bg={colors[field.backgroundColor] || colors.purple}
						color={
							(field.backgroundColor == "offwhite" &&
								colors.black) ||
							colors.white
						}
						py={[9, 9, 9, 11]}
						flexDirection={[
							"column",
							"column",
							"column",
							"row-reverse",
						]}
						split={[
							["100%", "100%", "100%", "50%"],
							["100%", "100%", "100%", "50%"],
						]}
						// Inputs
						title={field.title}
						paragraph={field.paragraph}
						image={field.image && field.image.rendition.url}
						titleCTA={field.cta}
						href={field.ctaLink}
					/>,
				];
			case "section_block_four":
				return [
					...acc,
					<SplitContent
						key={uuidv1()}
						bg={colors[field.backgroundColor] || colors.purple}
						color={
							(field.backgroundColor === "offwhite" &&
								colors.black) ||
							colors.white
						}
						split={[
							["100%", "100%", "100%", "50%"],
							["100%", "100%", "100%", "50%"],
						]}
						// Inputs
						title={field.title}
						paragraph={field.paragraph}
						image={field.image && field.image.rendition.url}
					/>,
				];
			case "section_block_five":
				return [
					...acc,
					<FullWidthContent
						key={uuidv1()}
						title={field.title}
						subTitle={field.subTitle}
						paragraph1={field.paragraph}
						paragraph2={field.paragraph2}
					/>,
				];
			case "section_block_six":
				return [
					...acc,
					<ParagraphSection
						key={uuidv1()}
						showNavigation={false}
						content={field.paragraph}
					/>,
				];
			case "section_block_seven":
				return [
					...acc,
					<SplitContent
						key={uuidv1()}
						colorCTA={colors.black}
						// Inputs
						title={field.title}
						paragraph={field.paragraph}
						titleCTA={field.cta}
						href={field.ctaLink}
						image={field.image && field.image.rendition.url}
					/>,
				];
			case "section_block_eight":
				return [
					...acc,
					<SplitContent
						key={uuidv1()}
						bg={colors[field.backgroundColor] || colors.lightblue}
						color={
							(field.backgroundColor === "offwhite" &&
								colors.black) ||
							colors.white
						}
						colorCTA={
							(field.backgroundColor === "offwhite" &&
								colors.black) ||
							colors.white
						}
						flexDirection={["column", "column", "row"]}
						split={[["100%", "100%", "50%"]]}
						paragraph={field.paragraph}
						titleCTA={field.cta}
						title={field.title}
						href={field.ctaLink}
						replaceImage={() => (
							<DonationBar
								raised={field.detailsDonationsRaisedAmount}
								donations={field.detailsDonationsCount}
								goal={field.detailsDonationsGoalAmount}
							/>
						)}
					/>,
				];
			case "section_block_nine":
				return [
					...acc,
					<SplitContent
						key={uuidv1()}
						bg={colors[field.backgroundColor] || colors.lightblue}
						color={
							(field.backgroundColor === "offwhite" &&
								colors.black) ||
							colors.white
						}
						colorCTA={
							(field.backgroundColor === "offwhite" &&
								colors.black) ||
							colors.white
						}
						title={field.title}
						flexDirection={[
							"column-reverse",
							"column-reverse",
							"row-reverse",
						]}
						split={[["100%", "100%", "50%"]]}
						paragraph={field.paragraph}
						titleCTA={field.cta}
						href={field.ctaLink}
						replaceImage={() => (
							<DonationBar
								raised={field.detailsDonationsRaisedAmount}
								donations={field.detailsDonationsCount}
								goal={field.detailsDonationsGoalAmount}
							/>
						)}
					/>,
				];
			case "section_block_ten":
				return [
					...acc,
					<SubsectionText
						key={uuidv1()}
						paragraphs={[field.paragraph, field.paragraph2]}
						bg={colors[field.backgroundColor] || colors.lightblue}
						color={
							(field.backgroundColor === "offwhite" &&
								colors.black) ||
							colors.white
						}
					/>,
				];
			case "section_block_eleven":
				return [
					...acc,
					<SubsectionText
						key={uuidv1()}
						paragraphs={[
							field.paragraph,
							field.paragraph2,
							field.paragraph3,
						]}
						bg={colors[field.backgroundColor] || colors.lightblue}
						color={
							(field.backgroundColor === "offwhite" &&
								colors.black) ||
							colors.white
						}
					/>,
				];
			case "banner_block":
				return [
					...acc,
					<Flex
						justifyContent="center"
						alignItems="center"
						pt={8}
						bg={field.backgroundColor}
						key={uuidv1}
					>
						<Flex
							justifyContent="center"
							alignItems="center"
							maxWidth={["100%", "80%", "70%"]}
							width={["100%"]}
							flexDirection={["column-reverse", "row"]}
						>
							<Flex
								width={["100%", "50%"]}
								justifyContent="center"
							>
								<Flex width={["70%", "70%", 500]}>
									<Image
										src={
											field.image &&
											field.image.rendition &&
											field.image.rendition.url
										}
									/>
								</Flex>
							</Flex>
							<Flex
								width={["100%", "50%"]}
								mr={[0, 9]}
								mb={[40, 0]}
								justifyContent="center"
								alignItems={["center", "flex-end"]}
								textAlign={["center", "right"]}
								flexDirection="column"
							>
								<Flex mb={[6]}>
									<Text
										as="h2"
										my={4}
										css={`
											text-transform: uppercase;
											font-size: 32px;
										`}
									>
										{field.title}
									</Text>
								</Flex>

								<Flex mb={[6]}>
									<ButtonLink
										css="cursor: pointer;"
										bg="#ffffff"
										color="#22222"
										variant="inline"
										href={field.buttonHref}
									>
										{field.buttonCta}
									</ButtonLink>
								</Flex>
							</Flex>
						</Flex>
					</Flex>,
				];
			case "embed_flippable":
				return [
					...acc,
					<Box
						my={8}
						px={["0", "0", "64px"]}
						key="embed_flippable"
						dangerouslySetInnerHTML={{ __html: field.value.code }}
					/>,
				];
			default:
				return dev
					? [
							...acc,
							<div key="unknown">
								Type: {type}
								{console.log(field.value)}
							</div>,
					  ]
					: acc;
		}
	}, []);
};
