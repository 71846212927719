import { Text, Flex } from "rebass";
import { default as NextBase } from "next/link";

import { Hide } from "../utilities";
import { colors } from "../../styleguide/theme";

export const HoverCard = (props) => {
	return (
		<>
			{/* DESKTOP VERSION */}
			<Hide
				width={
					props.width ? props.width : ["100%", "100%", "50%", "33.3%"]
				}
				hide={[0, 0, 1, 1]}
			>
				<NextBase passHref href={props.href}>
					<Flex
						className="card"
						width={["100%"]}
						flexDirection={"column"}
						justifyContent={"flex-end"}
						mt={[0, 0, 6, 0]}
						css={`
							min-height: 425px;
							min-width: 300px;
							height: ${props.height ? props.height : "35vw"};
							transition: height 0.3s;
							position: relative;
							background-image: url(${props.image});
							background-position: center;
							background-size: cover;
							box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
								0 3px 6px rgba(0, 0, 0, 0.23);
							&:hover {
								box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
									0 10px 10px rgba(0, 0, 0, 0.22);
								transform: scale(1.03);
								z-index: 1;
							}
							&:hover .card__content--hover {
								background-color: ${colors.hoverGradient};
								padding-top: 1.5rem;
								height: ${props.fullHover ? "100%" : "auto"};
							}
							&:hover .card__content--hover > p {
								display: block;
							}

							&:hover .card__content--text-shadow {
								text-shadow: 0px 2px 2px ${colors.lightblue};
							}
						`}
					>
						<Flex
							className="card__content--hover"
							flexDirection={"column"}
							alignItems={"flex-start"}
							justifyContent={"flex-end"}
							width={"100%"}
							p={"1.5rem"}
							pb={"0.8rem"}
							color={"#FFF"}
							css={`
								height: 50%;
								background-image: linear-gradient(
									rgba(255, 255, 255, 0),
									${colors.hoverGradient}
								);
								transition: 0.3s;
							`}
						>
							{props.title && (
								<Text
									fontSize={[1, 1, 2]}
									as="h4"
									mb={"0.5rem"}
									css={`
										text-transform: uppercase;
									`}
								>
									{props.title}
								</Text>
							)}
							{props.paragraph && (
								<Text
									as="p"
									css={`
										text-align: left;
										display: none;
									`}
								>
									{props.paragraph}
								</Text>
							)}
						</Flex>
					</Flex>
				</NextBase>
			</Hide>
			{/* MOBILE VERSION */}
			<Hide width={"100%"} hide={[1, 1, 0, 0]}>
				<NextBase passHref href={props.href}>
					<Flex
						className="card"
						width={["100%"]}
						flexDirection={"column"}
						justifyContent={"flex-end"}
						mt={[10, 10, 6, 0]}
						color={
							props.mobileFontColor
								? props.mobileFontColor
								: "#FFF"
						}
					>
						{/* IMAGE */}
						<Flex
							width={"100%"}
							flexDirection={"column"}
							justifyContent={"flex-end"}
							css={`
								height: 55vw;
								background-image: url(${props.image});
								background-position: center;
								background-size: cover;
							`}
						>
							{/* OVERLAY */}
							<Flex
								className="card__content--hover"
								flexDirection={"column"}
								alignItems={"flex-start"}
								justifyContent={"flex-end"}
								width={"100%"}
								p={"1.5rem"}
								pb={"0.8rem"}
								color={"#FFF"}
								css={`
									height: 50%;
									background-image: linear-gradient(
										rgba(255, 255, 255, 0),
										${colors.hoverGradient}
									);
									transition: 0.3s;
								`}
							></Flex>
						</Flex>
						{/* TITLE */}
						{props.title && (
							<Text
								fontSize={[2]}
								as="h4"
								mt={7}
								mb={4}
								css={`
									text-transform: uppercase;
								`}
							>
								{props.title}
							</Text>
						)}
						{props.paagraph && (
							<Text as="p">{props.paragraph}</Text>
						)}
					</Flex>
				</NextBase>
			</Hide>
		</>
	);
};
