import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from "rebass";

export function BannerStripe(props) {
    return (
        <React.Fragment>
            {/* Banner Shell Layout */}
            <Flex 
                width='100vw'
                bg='transparent'
                alignItems='flex-end'
                {...props}
            >
                {/* Banner Shell */}
                <Flex 
                    flexDirection={['column', 'column', 'row', 'row']}
                    width='100%'
                    bg={props.bg ? props.bg : 'transparent'}
                    css={`
                    box-shadow: 0 10px 24px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
                    color: ${props.color ? props.color : 'black'};

                    @media only screen and (max-width: 768px) {
                        box-shadow: 0px 0 10px rgba(0, 0, 0, 0.8);
                    }
                `}>
                    { props.children }
                </Flex>
            </Flex>
        </React.Fragment>
    )
}

BannerStripe.propTypes = {
    left: PropTypes.object.isRequired,
    right: PropTypes.object.isRequired,
}

BannerStripe.defaultProps = {
    left: {
        content: {title: ''}
    },
    right: {
        content: {title: ''}
    }
}

export default BannerStripe;

