import { Button as RebassButton } from "rebass";
import styled from "styled-components";
import { colors } from "../../styleguide/theme";
import { position, boxShadow, system } from "styled-system";

// Add styled system tools + transition to accepted props and theme
const Base = styled((props) => <RebassButton {...props} />)(
	position,
	boxShadow,
	system({
		transition: true,
	})
);
export const Button = (props) => (
	<Base
		// Defaults
		m={[2]}
		px={[9]}
		py={[4]}
		borderRadius="22.5px"
		fontSize="2"
		fontWeight="500"
		css="user-select: none; cursor: pointer;"
		// Override defaults with props
		{...props}
		// Custom variant field for input + default + disabled
		variant={
			(props.variant || "primary") + (props.disabled ? "Disabled" : "")
		}
		css={`
			&:focus {
				outline: none;
			}
		`}
	>
		{props.children}
	</Base>
);
