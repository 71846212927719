import { layoutConfig } from '../../styleguide/theme';
import { Box, Flex } from 'rebass';
export class LayoutPadding extends React.Component {
    render() {
        const { children } = this.props;
        return (
            <Box width={'100%'} px={layoutConfig.paddingX} py={this.props.py ? this.props.py : null} {...this.props}>
                <Flex flexDirection="column">{children}</Flex>
            </Box>
        );
    }
}