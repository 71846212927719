export const navData = {
    loginCTA: "Hi, Jenny",
    CTA: "DONATE",
    items: [
        { verbose_name: "ABOUT US", href: "/about" },
        { verbose_name: "STORIES", href: "/family-stories" },
        { verbose_name: "EVENTS", href: "/events" },
        { verbose_name: "WAYS TO SUPPORT", href: "/donate" }
    ],
};
