import styled from "styled-components";
import { Box, Flex } from "rebass";
import {
	space,
	color,
	flexbox,
	typography,
	border,
	position,
	layout
} from "styled-system";
import { Button } from "./Button";
import { Input, InputError, InputLabel } from "./Input";
import { colors } from "../../styleguide/theme";

const FormComponent = styled.form`
    display: flex;
    flex-direction: column;
    ${space}
    ${flexbox}
    ${color}
    ${layout}
`;

const defaultHandler = e => e.preventDefault();

export class OneLineForm extends React.Component {
	constructor(props) {
		super(props);
		this.Form = React.createRef();
	}

	submitForm = () => {
		// Explicitly focus the text input using the raw DOM API
		// Note: we're accessing "current" to get the DOM node
		this.Form.current.dispatchEvent(new Event("submit"));
	};
	render() {
		return (
			<Flex {...this.props} onClick={() => true} flexDirection="column">
				<FormComponent
					id={"one-line-form"}
					// id="_form_35_"
					// method="POST"
					// action="https://reena97786.activehosted.com/proc.php"
					ref={this.Form}
					onSubmit={e => {
						this.props.handleSubmit(e)
					}}
					flexDirection="column"
				>
					<input type="hidden" name="u" value="35" />
					<input type="hidden" name="f" value="35" />
					<input type="hidden" name="s" />
					<input type="hidden" name="c" value="0" />
					<input type="hidden" name="m" value="0" />
					<input type="hidden" name="act" value="sub" />
					<input type="hidden" name="v" value="2" />
					<Flex
						flexDirection="row"
						alignItems="center"
						justifyContent="space-between"
					>
						{this.props.label && (
							<InputLabel px="2" py="1" fontSize="0">
								{this.props.label}
							</InputLabel>
						)}
						{this.props.error && (
							<InputError px="2" pb="1" color="red" fontSize="0">
								{this.props.error}
							</InputError>
						)}
					</Flex>
					<Flex flexDirection={["column", "row", "column", "row"]}>
						<Input
                            fontSize={["0"]}
                            name="email"
							width={this.props.inputWidth || "90%"}
							color={colors.white}
							px="6"
							py={[5, 0, 5, 0]}
							mb={[4, "0px", 4, "0px"]}
							placeholder={`${
								this.props.placeholder
									? this.props.placeholder
									: "Type something"
							}`}
							backgroundColor={`${
								this.props.inputBackground
									? this.props.inputBackground
									: "transparent"
							}`}
							border="none"
							error={this.props.error}
							textTransform={"uppercase"}
							placeholderColor={"white"}
							noFocus={true}
						/>
						{this.props.button ? (
							// this.props.button(this.Form.current)
							this.props.button(this.submitForm)
						) : (
							<Button
								borderRadius="0px 5px 5px 0px"
								fontWeight="600"
								px="5"
								fontSize="1"
								m="0"
								id="erase"
								disabled={this.props.disabled}
								variant="inline"
								type="submit"
								onClick={() => {}}
							>
								Submit
							</Button>
						)}
					</Flex>
				</FormComponent>
			</Flex>
		);
	}
}
