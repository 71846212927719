import styled from "styled-components";
import { Box, Flex, Text } from "rebass";
import { colors } from "../../styleguide/theme";
// Components
import { IconFlip} from "../icons";
import { Link } from "../links/Link";
import { Input } from "./Input";
import { variant, border } from "styled-system";
import Downshift from "downshift";

const themed = key => props => props.theme[key];
const dropdownDisplays = variant({ key: "dropdownDisplays" });
const DropDownDisplayBase = styled(props => <Flex {...props} />)(
	border,
	dropdownDisplays,
	themed("dropdownDisplays")
);
const DropdownDisplay = ({
	icon,
	placeholder,
	getInputProps,
	getToggleButtonProps,
	isOpen,
	variant
}) => {
	return (
		<DropDownDisplayBase
			{...getToggleButtonProps()}
			variant={variant || "primary"}
			width="100%"
			justifyContent="space-between"
			// my="2"
			css={`
				-webkit-appearance: initial;
				user-select: none;
			`}
		>
			<Input
				readOnly={true}
				placeholder={placeholder || "- -"}
				backgroundColor={"#1b8de5"}
				color="white"
				css="user-select: none;"
				pl={6}
				fontSize={"16px"} // Required to remove iPhone zoom
				// https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone
				border="none"
				width={"100%"}
				{...getInputProps()}
				value={getInputProps().value}
			/>
			<Flex backgroundColor="#3095e3" p={5}>
				<IconFlip
					draggable="false"
					flipped={isOpen}
					width="25px"
					src={icon || "/static/icons/misc/chevron.svg"}
				/>
			</Flex>
		</DropDownDisplayBase>
	);
};

const dropdownLists = variant({ key: "dropdownLists" });
const DropDownListBase = styled(props => <Text {...props} />)(dropdownLists, themed("dropdownLists"));
const DropdownItem = props =>
	props.href ? (
		<Link
			data-testid={"dropdown-item"}
			href={props.href}
			{...props}
			as="li"
			css={`
				user-select: none;
				list-style: none;
				position: relative;
				z-index: 1;
				/* color: black; */
				${({ highlighted, selected }) => {
					const hello = 21;
					if (highlighted) {
						return `
                      background #1b8de5;
                    `;
					}
					// if (selected) {
					//     return `
					//     background: ${colors.grey};
					//   `;
					// }
				}};
			`}
		>
			{props.verbose_name}
		</Link>
	) : (
		<Text
			data-testid={"dropdown-item"}
			{...props}
			as="li"
			backgroundColor="white"
			color="black"
			css={`
				z-index: 1;
				user-select: none;
				list-style: none;
				position: relative;
				${({ highlighted, selected }) => {
					const hello = 21;
					if (highlighted) {
						return `
                    color: white;
                    background #3095e3;
                    `;
					}
					if (selected) {
						return `
                    color: black;
                    background white;
                  `;
					}
				}};
			`}
		>
			{props.verbose_name}
		</Text>
	);

export const Dropdown = ({
	width,
	icon,
	link,
	placeholder,
	selectedIndex,
	variant = "primary",
    options = [],
	onChange,
	testID, 
}) => (
	<Downshift
		id="dropdown"
		itemToString={item => (item && item.verbose_name) || ""}
        initialSelectedItem={options[selectedIndex] || null}
        onChange={onChange}
	>
		{({
			getInputProps,
			getItemProps,
			getLabelProps,
			getMenuProps,
			getToggleButtonProps,
			isOpen,
			highlightedIndex,
			selectedIndex,
			selectedItem,
			getRootProps,
		}) => (
			<div>
				<Flex flexDirection="column" data-testid={testID || 'dropdown-wrapper'}>
					<DropdownDisplay
						icon={icon}
						placeholder={placeholder}
						getInputProps={getInputProps}
						getToggleButtonProps={getToggleButtonProps}
						isOpen={isOpen}
						variant={variant}
						link={link}
					/>
					<Box css="position: relative;" width="100%">
						<Box
							width={width || "100%"}
							css={`
								transition: 300ms ease-in-out;
								overflow: hidden;
								${isOpen
									? "box-shadow: 2px 1px 6px 2px rgba(187, 187, 187, 0.26);"
									: ""}
								position: absolute;
								max-height: ${isOpen ? "500px" : "0px"};
							`}
						>
							<DropDownListBase
								{...getMenuProps()}
								css={`
									position: relative;
								`}
								variant={variant}
							>
								{options.map((item, index) => (
									<DropdownItem
										px={4}
										py={4}
										key={index}
										// onClick={() => {
										//     this.handleClick(index);
										// }}
										{...getItemProps({
											key: index,
											index: index,
											item: item
										})}
										highlighted={highlightedIndex === index}
										selected={selectedItem === item}
										value={item.value}
										link={item.link}
										verbose_name={item.verbose_name}
										variant={variant}
									/>
								))}
							</DropDownListBase>
						</Box>
					</Box>
				</Flex>
			</div>
		)}
	</Downshift>
);
