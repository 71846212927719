import Head from "next/head";
// Config
import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig();
const { SITE_NAME, DEFAULT_TITLE, DEFAULT_DESCRIPTION, SITE_TAB_DESCRIPTION } = publicRuntimeConfig;
// Components
import { Box, Flex } from "rebass";

import { Nav, } from "../content/Nav";
import { Footer } from "../content/Footer";
// Mock Data
import { navData } from "../../../mocks";

export class Layout extends React.Component {
    render() {
        const { children, title = DEFAULT_TITLE, searchDescription, tabDescription = SITE_TAB_DESCRIPTION, error } = this.props;
        return (
            <Box {...this.props}>
                <Head>
                    <title>{`${title ? `${title} | ` : ""}${SITE_NAME}${tabDescription ? ` | ${tabDescription}` : ""}`}</title>
                    <meta charSet="utf-8" />
                    <meta
                        name="viewport"
                        content="initial-scale=1.0, width=device-width"
                    />
                    <meta 
                        name="description"
                        content={searchDescription ? searchDescription : DEFAULT_DESCRIPTION}
                    />
                </Head>
                <header style={{ position: "relative" }}>
                    <Nav as="nav" {...navData} />
                </header>
                <Flex flexDirection="column">
                    {children}
                </Flex>
                <footer>
                    <Footer error={error}/>
                </footer>
            </Box>
        );
    }
}
