import React, { Component } from "react";
import { Image, Box, Flex, Text, Button } from "rebass";
import { default as NextBase } from "next/link";
import Slider from "react-slick";

// Component Imports
import { Hide, LayoutPadding } from "../utilities";
import { colors } from "../../styleguide/theme";

const SliderNextBtn = (props) => {
	const { style, onClick } = props;
	return (
		<Hide hide={[0, 0, 1]}>
			<a
				className="slick-arrow"
				href="#next" // Accessibility
				role="button" // Accessibility
				name="next"
				style={{
					...style,
					background: "#FFF",
					borderRadius: "50%",
					width: "55px",
					height: "55px",
					position: "absolute",
					top: "30%",
					left: "-8rem",
					zIndex: 1,
					transform: "translateY(-30%)",
				}}
				onClick={onClick}
			>
				<Image
					alt="Previous"
					src="/static/icons/misc/Arrow-Circle-Left.svg"
				/>
			</a>
		</Hide>
	);
};

const SliderPrevBtn = (props) => {
	const { style, onClick } = props;
	return (
		<Hide hide={[0, 0, 1]}>
			<a
				className="slick-arrow"
				href="#next" // Accessibility
				role="button" // Accessibility
				name="Previous"
				style={{
					...style,
					background: "#FFF",
					borderRadius: "50%",
					width: "55px",
					height: "55px",
					position: "absolute",
					top: "60%",
					left: "-8rem",
					transform: "translateY(-60%)",
					zIndex: 1,
				}}
				onClick={onClick}
			>
				<Image
					alt="Next"
					src={"/static/icons/misc/Arrow-Circle-Right.svg"}
				/>
			</a>
		</Hide>
	);
};

const Card = ({ image, title, date, location, color, href }) => (
	<NextBase href={href}>
		<Flex
			flexDirection={"column"}
			color={color ? color : colors.white}
			mr={(5, 5, 7, 7)}
			ml={"0"}
			mb={[2, 2, 4, 4]}
			width={["265px"]}
			css={`
				cursor: pointer;

				&:hover .detail-card--hover {
					box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
						0 10px 10px rgba(0, 0, 0, 0.22);
				}

				&:hover .card__content--text {
					display: flex;
				}

				@media only screen and (max-width: 400px) {
					width: 155px;
				}
			`}
		>
			<Flex
				className={"detail-card--hover"}
				alignItems={"flex-end"}
				width={"100%"}
				p={6}
				css={`
					background-image: url("${image}");
					background-position: center;
					background-size: cover;
					background-repeat: no-repeat;
					height: 265px;

					@media only screen and (max-width: 480px) {
						height: 50vw;
						width: 50vw;
					}
				`}
			>
				<Flex
					className={"card__content--text"}
					color={colors.white}
					css={`
						display: none;
					`}
				>
					<Flex mr={[4, 4, 5, 5]}>
						<Image
							width={"35px"}
							src={"/static/icons/misc/Arrow-Left.svg"}
						/>
					</Flex>
					<Text
						as="h5"
						css={`
							text-transform: uppercase;
							text-shadow: 0px 2px 2px ${colors.lightblue};
						`}
					>
						learn more
					</Text>
				</Flex>
			</Flex>
			<Text
				as="h5"
				my={4}
				fontSize={[2, 2, 3, 3]}
				css={`
					text-transform: uppercase;
				`}
			>
				{title}
			</Text>
			<Text
				as="p"
				fontWeight={"100"}
				css={`
					line-height: 20px;
				`}
			>
				{date}
			</Text>
			<Text
				as="p"
				fontWeight={500}
				css={`
					font-style: italic;
					line-height: 20px;
				`}
			>
				{location}
			</Text>
		</Flex>
	</NextBase>
);

export class CardSlider extends Component {
	static defaultProps = {
		title: "Upcoming Events",
		paragraph: "",
		data: [],
		bg: colors.lightblue,
		buttonHighlight: false,
	};

	render() {
		const {
			title,
			subTitle,
			paragraph,
			data,
			bg,
			color,
			buttonHighlight,
			href,
			buttonTitle,
		} = this.props;
		const settings = {
			infinite: false,
			slidesToShow: 3.5,
			slidesToScroll: 3,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3,
					},
				},
				{
					breakpoint: 1080,
					settings: {
						slidesToShow: 2.5,
						slidesToScroll: 2,
					},
				},
				{
					breakpoint: 950,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
					},
				},
				{
					breakpoint: 660,
					settings: {
						slidesToShow: 1.5,
						slidesToScroll: 1,
					},
				},
			],

			nextArrow: <SliderNextBtn />,
			prevArrow: <SliderPrevBtn />,
			appendDots: (dots) => (
				<div
					style={{
						width: "100%",
						display: "flex",
						justifyContent: "center",
						backgroundColor: "transparent",
						position: "absolute",
						margin: 0,
						padding: 0,
						bottom: "5px",
					}}
				>
					<ul style={{ margin: 0, padding: 0 }}> {dots} </ul>
				</div>
			),
			customPaging: () => {
				return (
					<div
						className="slick__btn-active"
						style={{
							width: "10px",
							height: "10px",
							borderRadius: "50%",
							border: "1px white solid",
						}}
					></div>
				);
			},
		};
		return (
			<Flex width={"100%"} bg={bg} color={color ? color : "#FFF"}>
				<LayoutPadding py={10}>
					{/* CONTENT CONTAINER */}
					<Flex flexDirection={"column"}>
						<Flex justifyContent="space-between">
							<Flex
								width={["100%", "100%", "100%", "50%"]}
								flexDirection={"column"}
							>
								{/* TITLE */}
								<Text
									as="h2"
									fontSize={[6, 6, 8, 8]}
									pb={8}
									css={`
										line-height: ${1};
										text-transform: uppercase;
										letter-spacing: 1.8px;
										@media only screen and (max-width: 768px) {
											letter-spacing: 0px;
										}
									`}
								>
									{title}
								</Text>
								{subTitle && (
									<Text
										as="h3"
										fontWeight={100}
										fontSize={[4]}
										pb={5}
										css={`
											text-transform: uppercase;
										`}
									>
										{subTitle}
									</Text>
								)}
								<Text
									as="p"
									fontWeight={100}
									pb={8}
									css={`
										line-height: 20px;
									`}
								>
									{paragraph}
								</Text>
							</Flex>
							{/* MOVE SEE ALL EVENTS BUTTON TO BOTTOM OF COLUMN IF NOT DESKTOP */}
							<Hide hide={[0, 0, 0, 1]}>
								<NextBase passHref href={href}>
									<Button
										css={`
											height: 45px;
											background: transparent;
										`}
									>
										<Flex
											alignItems={"center"}
											css={`
												height: 100%;
												.event-button-text {
													transition: 0.25s ease;
												}
												&:hover .event-button-text {
													transform: translateY(-3px);
												}
											`}
										>
											{/* change button image based on background color */}
											<Image
												alt="See All Events"
												className={"event-button-image"}
												width={"2.5rem"}
												src={`${
													buttonHighlight
														? "/static/icons/misc/Arrow-Left.svg"
														: "/static/icons/misc/Arrow-Left-Blue.svg"
												}`}
											/>
											<Text
												className={"event-button-text"}
												ml={[2, 2, 4]}
												fontSize={[0, 0, 1]}
												color={color}
												css={`
													text-transform: uppercase;
													text-shadow: ${buttonHighlight
														? `0px 2px 2px ${colors.purple}`
														: "none"};
												`}
											>
												{`${buttonTitle}`}
											</Text>
										</Flex>
									</Button>
								</NextBase>
							</Hide>
						</Flex>
					</Flex>
					{/* CARD CONTAINER */}
					{!!data.length ? (
						<Box
							ml={[0, 0, 13]}
							css={`
								.slick-track {
									margin-left: 0;
								}
							`}
						>
							<Slider {...settings}>
								{data.map((card, i) => {
									return (
										<Card
											key={card.id}
											title={card.title}
											location={card.location}
											date={card.date}
											color={color}
											image={card.image}
											href={card.href}
										/>
									);
								})}
							</Slider>
						</Box>
					) : (
						<Text as="p">{`No ${title} available`}</Text>
					)}

					{/* MOBILE SEE ALL BUTTON */}
					<Hide hide={[1, 1, 1, 0]}>
						<NextBase passHref href={href}>
							<Button
								mt={[6, 6, 6, "0"]}
								css={`
									height: 40px;
									background: transparent;
								`}
							>
								<Flex
									alignItems={"center"}
									css={`
										height: 100%;
										.event-button-text {
											transition: 0.25s ease;
										}
										&:hover .event-button-text {
											transform: translateY(-3px);
										}
									`}
								>
									{/* change button image based on background color */}
									<Image
										width={"2.5rem"}
										src={`${
											buttonHighlight
												? "/static/icons/misc/Arrow-Left.svg"
												: "/static/icons/misc/Arrow-Left-Blue.svg"
										}`}
									/>
									<Text
										className={"event-button-text"}
										ml={[2, 2, 4]}
										fontSize={[0, 0, 1]}
										color={color}
										css={`
											text-transform: uppercase;
											text-shadow: ${buttonHighlight
												? `0px 2px 2px ${colors.purple}`
												: "none"};
										`}
									>
										{`${buttonTitle}`}
									</Text>
								</Flex>
							</Button>
						</NextBase>
					</Hide>
				</LayoutPadding>
			</Flex>
		);
	}
}
