// Libraries
import { default as NextBase } from "next/link";
import { Flex, Image } from "rebass";

// Component Imports
import { Text } from "../info";

export const BackButton = ({ title, href }) => (
	<NextBase passHref href={`${href}`}>
		<Flex
			justifyContent={["center", "center", "flex-start"]}
			alignItems={"center"}
			css={`
				&:hover {
					cursor: pointer;
				}
			`}
		>
			<Text
				as="p"
				styling={`
                    text-transform: uppercase;
                `}
			>
				{title}
			</Text>
			<Flex ml={[4]} py={6}>
				<Image
					width={"3rem"}
					src={"/static/icons/misc/Arrow-Right.svg"}
				/>
			</Flex>
		</Flex>
	</NextBase>
);
