import styled from 'styled-components';
import {system} from 'styled-system';
import {Box} from 'rebass';

export const Hide = styled(props => <Box {...props} />)`
    ${system({
        hide: {
            property: "display",
            transform: val => val === 0 && "none" || "block"
        }
    })}
`