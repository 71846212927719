import styled from 'styled-components';
import {colors} from '../../styleguide/theme';
import {SVG} from '../icons'

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: circle(50% at 50% 50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${props => props.checked ? colors.lightblue : ''};
  border-radius: 20px;
  border: 2px solid ${colors.lightblue};
  transition: all 150ms;
`

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin: 10px;
`

export class Checkbox extends React.Component {
    state = {
        checked: false
    }
    render() {
        let {checked} = this.state;
        return (
        <CheckboxContainer onClick={() => this.setState({checked: !this.state.checked})}>
          <HiddenCheckbox onChange={() => true} checked={checked} {...this.props} />
          <StyledCheckbox checked={checked}>
              <SVG viewBox='0 0 24 24'>
                <polyline points="20 6 9 17 4 12"/>
              </SVG>
          </StyledCheckbox>
        </CheckboxContainer>
      )  }
} 