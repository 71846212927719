// Libraries
import { Element } from 'react-scroll';
import { Flex, Text as TextBase } from "rebass";

import { colors } from '../../../styleguide/theme';
// Component Imports
import { Text } from '../../info';
import { LayoutPadding } from '../../utilities';

export const PageIntro = ({title, subTitle, paragraph, paragraph2, bg, body}) => {
    return (
        <Flex bg={bg}>
            <Element name='missionStatement' style={{width: '100%'}}>
                <LayoutPadding py={11}>
                    {/* BANNER */}
                    <Flex 
                        width={'100%'}
                        flexDirection={['column', 'column', 'row', 'row']}
                    >
                        {/* TITLE */}
                        <Flex 
                            width={['100%','100%','100%','50%']}
                            mr={['0', '0', 6, '0']}
                            mb={[6, 6, 6, '0']}
                            color={colors.darkblack}
                        >
                            <Text 
                                as='h2' 
                            >
                                {title}
                            </Text>
                        </Flex>
                        {/* SUB-TITLE */}
                        <Flex                         
                            width={['100%','100%','100%','50%']}
                            flexDirection={'column'}
                        >
                            { subTitle && <Text 
                                as='h3' 
                                fontWeight={100} 
                                pb={5}
                            >
                                {subTitle}
                            </Text>
                            }
                            {paragraph && <Text as='p'>
                                {paragraph}
                            </Text>
                            }
                            { paragraph2 && <Text mt={1} as='p'>
                                {paragraph2}
                            </Text>
                            }
                            { body && <TextBase as="div" fontSize={[1]} fontWeight={100} css={`line-height: 22px;`} dangerouslySetInnerHTML={{ __html: body }} /> }
                        </Flex>
                    </Flex>
                </LayoutPadding>
            </Element>
        </Flex>
    ) 
}