import {Box} from "rebass";
import styled from "styled-components";
import { system } from "styled-system";

export const Circle = styled(props => <Box {...props} />)(
    system({
        size: {
            properties: ["height", "width", "border-radius"]
        }
    })
);