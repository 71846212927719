import { Flex } from "rebass";
import { system, variant, boxShadow } from "styled-system";
import { NavButtonLink } from '../links'
import { Dropdown } from "../inputs/Dropdown";
import styled from "styled-components";

const themed = key => props => props.theme[key];
const filters = variant({ key: "filters" });
const Base = styled(props => <Flex {...props}/>)(filters, themed("Filters"), boxShadow);

export class Filter extends React.Component {

    render() {
        let {options, verbose_name, icon, placeholder, variant} = this.props;
        switch (variant) {
            case "horizontal":
                return (
                    <Base variant={"horizontal"} p={2} {...this.props}>
                        {options.map((item, i) => (
                            <NavButtonLink
                                onClick={e => e.preventDefault()}
                                href={'#'}
                                key={i}
                                fontSize={1}
                                fontWeight={"bold"}
                                variant="ghost"
                                borderRadius="25px"
                                my="0"
                                mx="2"
                                py={2}
                                px={5}
                            >
                                {item.verbose_name}
                            </NavButtonLink>
                        ))}
                    </Base>
                );
            case "login":
                return (
                    <Dropdown
                        icon={icon}
                        options={options}
                        variant="login"
                        placeholder={placeholder}
                        {...this.props}
                    />
                );
            default:
                return (
                    <Base {...this.props}>
                        <Dropdown
                            icon={icon}
                            options={options}
                            variant="filter"
                            selectedIndex={0}
                        />
                    </Base>
                );
        }
    }
}
