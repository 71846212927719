import styled from "styled-components";
import { Flex } from "rebass";
import {
	space,
	color,
	flexbox,
	typography,
	border,
	position,
	layout
} from "styled-system";
import { Button,} from "./Button"
import { Input, InputError, InputLabel, TextArea} from './Input'
import { Dropdown,} from './Dropdown'
import { CheckCard,} from './CheckCard'
import { colors } from "../../styleguide/theme";

const FormComponent = styled.form`
    display: flex;
    flex-direction: column;
    ${space}
    ${flexbox}
    ${color}
    ${layout}
`;

const FormItem = ({label, name, type, options, onChange, placeholder, error, value, ...rest}) => {
	// Default does nothing without error!
	const defaultHanlder = () => true;
	return (
		<Flex {...rest} css="background-color: red;" flexDirection="column">
			<Flex
				flexDirection="row"
				alignItems="center"
				justifyContent="space-between"
			>
				{label && (
					<InputLabel px="2" py="1" fontSize="0">
						{label}
					</InputLabel>
				)}
			</Flex>
			{(() => {
				if (type === "dropdown") {
					return <Dropdown variant="form" options={options} onChange={onChange}/>;
				} else if (type === "checkCard") {
					return <CheckCard onChange={onChange} />;
				} else if (type === "textArea") {
					return (
						<TextArea
							placeholder={placeholder}
							error={error}
							fontSize={["2", "2", "2"]}
							ml="1"
							px="3"
							py="4"
							onChange={onChange}
							type={type}
							value={value}
							name={name}
							error={error}
						/>
					);
				} else {
					return (
						<Input
							fontSize={["2", "2", "2"]}
							ml="1"
							px="3"
							py="4"
							type={type}
							value={value}
							name={name}
							placeholder={placeholder}
							error={error}
							onChange={onChange}
						/>
					);
				}
			})()}
			<InputError px="2" pb="1" color="red" fontSize="0">
				{error}
			</InputError>
		</Flex>
	);
};

export class Form extends React.Component {
	// Default does nothing without error!
	defaultHandler = e => e.preventDefault();

	static FormItem = FormItem;

	render() {
		const { error } = this.props;
		return (
			<FormComponent
				onSubmit={this.props.onSubmit || this.defaultHandler}
				{...this.props}
			>
				{this.props.children}
			</FormComponent>
		);
	}
}
