import { Flex } from "rebass";

// Components
import { Text } from "../info";
import { Layout } from "../fragments/ui/Layout";
import { Section } from "../fragments/ui/Section";
import { LayoutPadding } from "../utilities";
import { Link } from "../links";

export const Error = ({ statusCode, message }) => {
	return (
		<React.Fragment>
			<LayoutPadding py={[9, 9, 11]}>
				<Flex mt={13} flexDirection={"column"}>
					<Text mb={5} as="h1">
						{message || "Oops, something went wrong..."}
					</Text>
					<Text as="p" fontSize={3}>
						{statusCode
							? `Status Code: ${statusCode}`
							: "An unknown error has occurred"}
					</Text>
					{/* <Section mb={600} /> */}
					<a
						href="/"
						style={{
							color: "#008EFA",
							textDecoration: "none",
							fontWeight: "bold",
							marginTop: "20px"
						}}
					>
						Back Reena Foundation Home
					</a>
				</Flex>
			</LayoutPadding>
		</React.Fragment>
	);
};
