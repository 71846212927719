import { Text, Box, Flex, Image, Card as Base } from "rebass";
import styled from "styled-components";
import { system } from "styled-system";

import {Link} from '../links'
import { Circle } from "../shapes";

export const Card = props => (
    <Base
        variant={props.variant || "standard"}
        flexDirection="column"
        css={`
            &:hover {
                transform: scale(1.02) translateZ(0);
            }
        `}
        {...props}
    >
        <Flex
            // backgroundColor={props.theme.buttons[props.variant || standard ].backgroundColor}
            flexDirection="column"
            px={5}
            css={`
                overflow: hidden;
            `}
        >
            <Flex justifyContent="center" my={4} alignItems="center">
                <Circle my="7" backgroundColor="pink" size={["100px", "125px"]}>
                    <Image />
                </Circle>
            </Flex>
            <Flex
                flexDirection="column"
                alignItems={"center"}
                color={"white"}
                mx={5}
                mb={9}
                css="user-select: none;"
            >
                <Text
                    fontSize={3}
                    fontWeight={1}
                    css="text-transform: uppercase; text-align: center;"
                >
                    {props.title || ""}
                </Text>
                <Text css="text-transform: uppercase;">{props.subtitle || ""}</Text>
            </Flex>
        </Flex>
        <Link width='100%' href={props.href || "#"}>
            <Flex
                css={`
                    border-radius: 0px 0px 10px 10px;
                `}
                justifyContent="center"
                alignItems="center"
                py={5}
                backgroundColor="white"
            >
                <Text
                    color="pink"
                    fontSize="1"
                    fontWeight="bold"
                    css="text-transform: uppercase;"
                >
                    {props.cta || 'view'}
                </Text>
            </Flex>
        </Link>
    </Base>
);
