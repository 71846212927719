import React, { Component } from "react";
import PropTypes from "prop-types";
import { Flex, Box, Text } from "rebass";
import { ButtonLink } from "../links";
import { colors } from "./../../styleguide/theme";
import { Icon } from "../icons";

export class CallToAction extends Component {
	static propTypes = {
		title: PropTypes.string.isRequired,
		subColor: PropTypes.string,
	};

	static defaultProps = {
		title: "Submit",
		subColor: "#FFF",
		href: "#",
	};

	render() {
		const {
			title,
			subColor,
			href,
			onClick,
			disabled,
			testID,
			type,
			loading = false,
		} = this.props;
		return (
			<Flex
				css={`
					&:hover .CTA-btn {
						background: ${colors.deepyellow};
					}

					&:hover .CTA-btn--text {
						font-weight: 500;
					}
				`}
			>
				<ButtonLink
					variant="transparent"
					css="
                        text-transform: uppercase; 
                        border-radius: 0; 
                        margin-right: auto;
                        padding: 0;  
                        cursor: pointer;
                    "
					fontSize={[0]}
					fontWeight={400}
					href={href}
					onClick={onClick}
					disabled={disabled}
					testID={testID}
					type={type}
				>
					<Flex alignItems="center" justifyContent="space-between">
						<Box
							className={`CTA-btn`}
							bg={!disabled ? colors.lightyellow : colors.grey}
							css={`
								display: inline-block;
								height: 55px;

								@media only screen and (max-width: 768px) {
									height: 40px;
								}
							`}
							width={["40px", "40px", "55px"]}
						/>
						<Flex
							width={["45px", "45px", "75px"]}
							css={`
								position: relative;
							`}
						>
							<Box
								width="100%"
								bg={subColor}
								css={`
									position: absolute;
									top: 50%;
									height: 1px;
									right: 15px;
									@media only screen and (max-width: 768px) {
										right: 10px;
									}
								`}
							/>
						</Flex>
						{loading ? (
							<Icon
								mt={-20}
								mb={-55}
								src={"/static/icons/misc/inline-loading.svg"}
							/>
						) : (
							<Text
								fontWeight={400}
								color={!disabled ? subColor : "grey"}
								className={"CTA-btn--text"}
							>
								{title}
							</Text>
						)}
					</Flex>
				</ButtonLink>
			</Flex>
		);
	}
}
