// Components
import { Flex, Box, Image, Text } from "rebass";
import { default as NextBase } from "next/link";
import ScrollLock, { TouchScrollable } from "react-scrolllock";
import { Icon } from "../../icons";
import { Filter } from "../../filters";
import { Link, ButtonLink } from "../../links";
import { Hamburger } from "../../menus";
import { Hide } from "../../utilities";
import { colors } from "../../../styleguide/theme";

const NavItems = ({ items, isMenuOpen }) => (
	<Flex
		// my={[4, 4, 4, 8]}
		flexDirection={["column", "column", "column", "row"]}
		width="100%"
		justifyContent="flex-start"
	>
		{items.map((item, i) => (
			<Link
				variant={isMenuOpen ? "mobileHide" : "primary"}
				key={i}
				href={item.href}
				px={3}
				py={3}
				mx={"3px"}
				fontSize={[1, 2, 2, 2]}
				css="text-transform: uppercase; border-bottom: 5px solid transparent; border-top: 5px solid transparent"
				fontWeight="normal"
			>
				{item.verbose_name}
			</Link>
		))}
	</Flex>
);

export class Nav extends React.Component {
	state = {
		isMenuOpen: false,
		width: null,
	};

	updateWidth = () => {
		this.setState(
			{
				width: window.innerWidth,
			},
			() => {
				if (this.state.width > 1055 && this.state.isMenuOpen) {
					this.setState({
						isMenuOpen: false,
					});
				}
			}
		);
	};

	componentDidMount() {
		window.addEventListener("resize", this.updateWidth);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateWidth);
	}

	handleBackground = (disableNavGradient, isMenuOpen) => {
		if (disableNavGradient) {
			return "#FFF;";
		} else if (isMenuOpen) {
			return "linear-gradient(#FFF, #FFF);";
		} else {
			return "linear-gradient(#FFF, transparent);";
		}
	};

	render() {
		let {
			logo_url,
			items,
			onClickLogin,
			onClickCTA,
			CTA,
			loginCTA,
			onClickCart,
			cartURL,
			loginOptions,
			disableNavGradient,
		} = this.props;
		let { isMenuOpen } = this.state;
		// Call hook to lock body scroll
		// if (isMenuOpen) {

		// }

		return (
			<Box
				css={`
					width: 100%;
					position: absolute;
					top: 0;
					left: 0;
					z-index: ${this.state.isMenuOpen ? 15 : 1};
				`}
			>
				{/* Nav */}
				<TouchScrollable>
					{/* <Headroom> */}
					<Flex
						alignItems="flex-start"
						css={`
							height: 22vw;
							background-image: ${this.handleBackground(disableNavGradient, isMenuOpen)}
							transition: 350ms ease-in-out;
							min-height: 135px;

							// height @ 33vw for hamburger dropdown
							@media only screen and (max-width: 500px) {
								height: 32vw;
							}
						`}
					>
						<Flex
							py={9}
							px={[8, 11]}
							width="100%"
							alignItems={"center"}
						>
							{/* UPDATE: Replace with SVG */}
							<NextBase passHref href={"/"}>
								<Flex
									width={["50%", "50%", "10%"]}
									css={`
										${isMenuOpen
											? "visibility: hidden;"
											: ""}
									`}
								>
									<Box width={["55px", "55px", "70px"]}>
										<Image
											alt="Reena Foundation Logo"
											src="/static/icons/brand/Reena-Foundation-Logo.png"
											css={`
												&:hover {
													cursor: pointer;
												}
											`}
										/>
									</Box>
								</Flex>
							</NextBase>
							<Hide width={"60%"} hide={[0, 0, 0, 1]}>
								<NavItems items={items} />
							</Hide>
							<Flex ml="auto" justifyContent={"flex-end"}>
								<Hide hide={[0, 0, 0, 1]}>
									<Link
										variant="primary"
										href={"/contact"}
										px={3}
										py={3}
										mr={[8]}
										fontSize={[1, 2, 2, 2]}
										css={`
											text-transform: uppercase;
											border-bottom: 5px solid transparent;
										`}
										fontWeight="normal"
									>
										Contact Us
									</Link>
									<ButtonLink
										variant="donate"
										borderRadius={0}
										css="text-transform: uppercase; cursor: pointer;"
										fontSize={[1, 2, 2, 2, 2]}
										fontWeight={400}
										px={[7, 7, 7, 8]}
										py={5}
										tabIndex={0}
										href="/donate"
										css={`
											&:hover {
												background: ${colors.deepyellow};
												font-weight: 600;
											}
										`}
									>
										{CTA}
									</ButtonLink>
								</Hide>
								<Hide hide={[1, 1, 1, 0]}>
									<Flex>
										<Hamburger
											isOpen={this.state.isMenuOpen}
											onClick={(val) =>
												this.setState({
													isMenuOpen: val,
												})
											}
										/>
									</Flex>
								</Hide>
							</Flex>
						</Flex>
					</Flex>
					{/* </Headroom> */}
					<Flex
						backgroundColor="white"
						css={`
							position: fixed;
							overflow: hidden;
							transition: 350ms ease-in-out;
							height: ${isMenuOpen ? "100%" : "0%"};
							display: ${isMenuOpen ? "flex" : "none"};
							transition-delay: height 2s;
							margin-top: -2px;
							top: ${isMenuOpen ? "20%" : "0%"};
							left: 0;
						`}
						width={"100%"}
					>
						<Flex
							width={"100%"}
							flexDirection="column"
							ml={"30px"}
							// alignItems="center"
						>
							<NavItems isMenuOpen={isMenuOpen} items={items} />

							<Link
								variant={isMenuOpen ? "mobileHide" : "primary"}
								href={"/contact"}
								px={3}
								py={3}
								mx={"3px"}
								fontSize={[0, 0, 0, 0]}
								css="text-transform: uppercase; border-bottom: 5px solid transparent; border-top: 5px solid transparent"
								fontWeight="normal"
							>
								Contact Us
							</Link>
							<Flex mt={[8, 10]}>
								<ButtonLink
									variant="transparent"
									css="
                                        text-transform: uppercase; 
                                        border-radius: 0; 
                                        margin-right: auto; 
                                        position: relative;
                                    "
									fontSize={[0]}
									fontWeight={"normal"}
									px={"12px"}
									// py={3}
									// mx={3}
									// mt={6}
									href="/donate"
									width="180px"
								>
									<Flex alignItems="center">
										<Box
											bg={colors.lightyellow}
											css="display: inline-block; height: 40px"
											width="40px"
										/>
										<Box
											width="58px"
											bg={colors.darkblack}
											pl="30px"
											css="
                                                position: absolute;
                                                left: 40px;
                                                top: 25px;
                                                display: inline-block;
                                                height: 1px;
                                            "
										/>
										<Text ml="auto">{CTA}</Text>
									</Flex>
								</ButtonLink>
							</Flex>
						</Flex>
					</Flex>
				</TouchScrollable>
				{/* Locks Scroll based on open menu */}
				<ScrollLock isActive={isMenuOpen} />
			</Box>
		);
	}
}
