import styled from "styled-components";
import { Box, Flex } from "rebass";
import {
    space,
    color,
    flexbox,
    layout
} from "styled-system";

import {Button} from "./Button"
import {Input, InputError, InputLabel} from "./Input"

import { colors } from "../../styleguide/theme";

const FormComponent = styled.form`
    display: flex;
    flex-direction: column;
    ${space}
    ${flexbox}
    ${color}
    ${layout}
`;

const defaultHandler = e => e.preventDefault()

export const InputSearch = props => (
    <Flex 
        {...props} 
        width={props.inputWidth ? props.inputWidth : '100%'} 
        onClick={() => true} 
        flexDirection="column"
    >
        <FormComponent onSubmit={props.onSubmit || defaultHandler} flexDirection="column">
            <Flex
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
            >
                {props.label && (
                    <InputLabel px="2" py="1" fontSize="0">
                        {props.label}
                    </InputLabel>
                )}
                {props.error && (
                    <InputError px="2" pb="1" color="red" fontSize="0">
                        {props.error}
                    </InputError>
                )}
            </Flex>
            <Flex>
                <Input
                    fontSize={['0']}
                    width={"90%"}
                    color={colors.white}
                    px="6"
                    py={[3,0,5,5]}
                    // mb={[4, '0px', 4, '0px']}
                    placeholder={`${props.placeholder ? props.placeholder : "Type something"}`}
                    backgroundColor={`${props.inputBackground ? props.inputBackground : "transparent"}`}
                    border="none"
                    error={props.error}
                    textTransform={'uppercase'} 
                    noFocus={true}
                    placeholderColor={'white'}
                />
                { props.button ?
                    props.button() :
                    <Button
                        borderRadius="0px 5px 5px 0px"
                        fontWeight="600"
                        px="5"
                        fontSize="1"
                        m="0"
                        id="erase"
                        disabled={props.disabled}
                        variant='inline'
                        type='submit'
                    >
                        Submit
                    </Button>
                }
            </Flex>
        </FormComponent>
    </Flex>
);