import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import { Box, Image, Flex, Text } from "rebass";

import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig();
const { GOOGLE_API_KEY } = publicRuntimeConfig;

const Marker = ({ text, $hover }) => (
	<Flex
		flexDirection={"column"}
		justifyContent="center"
		alignItems="center"
		css={`
			position: relative;
		`}
	>
		{$hover && <ToolTip text={text} />}
		<Image
			width={"25px"}
			src={"/static/icons/misc/Location-Icon_Green.svg"}
		/>
	</Flex>
);

const ToolTip = ({ text }) => (
	<Flex
		bg="white"
		m={3}
		p={3}
		css={`
			box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
				0 6px 6px rgba(0, 0, 0, 0.23);
			position: absolute;
			top: -160%;
			left: 50%;
			transform: translateX(-50%);
			width: 150px;
			overflow: hidden;
			border-radius: 2px;
		`}
	>
		<Text>{text}</Text>
	</Flex>
);

export class BasicMap extends Component {
	state = {
		mounted: false,
		center: {
			lat: null,
			lng: null,
		},
	};

	componentDidMount() {
		this.setState({
			mounted: true,
			center: this.props.location,
		});
	}

	render() {
		return (
			<Box
				width={"100%"}
				css={`
					height: auto;
				`}
			>
				{this.state.mounted > 0 && (
					<GoogleMapReact
						bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
						center={this.state.center}
						defaultZoom={this.props.zoom || 15}
					>
						<Marker
							lat={this.props.location && this.props.location.lat}
							lng={this.props.location && this.props.location.lng}
							text={this.props.address}
						/>
					</GoogleMapReact>
				)}
			</Box>
		);
	}
}
