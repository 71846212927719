import { Flex, Text, Image, Card as Base } from "rebass";
import {Checkbox} from './Checkbox'
import { colors } from "../../styleguide/theme";

export const CheckCard = props => (
    <Base
        borderRadius="5px"
        py={2}
        px={4}
        border={`4px solid ${colors.green}`}
        {...props}
    >
        <Flex py={1}>
            <Flex width="72px">
                <Image
                    width="72px"
                    height='48px'
                    my={2}
                    src="static/icons/illustrations-hoop.jpg"
                />
            </Flex>
            <Flex
                color="black"
                fontSize="0"
                ml={5}
                mt={2}
                width="100%"
                flexDirection="column"
            >
                <Text>Curated Blog Image Section</Text>
                <Text>[Additional info about this add-on]</Text>
            </Flex>
            <Flex alignItems='center' justifyContent='flex-end' flexDirection="column">
                <Text fontWeight="1">$$$</Text>
                <Checkbox type="checkbox" />
            </Flex>
        </Flex>
    </Base>
);
