import React from 'react';
import { Flex } from 'rebass';

export function Section(props) {
    const { children } = props;
    return (
        <Flex {...props} css={`min-height: 52vw; ${props.css}`}>
            { children }   
        </Flex>
    )
}
