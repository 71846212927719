import { Flex, Box } from "rebass";

// Component Imports
import { Text } from "../info/";
import { colors } from "../../styleguide/theme";

export const DonationTracker = ({ raised, donations, goal, ...rest }) => (
	<Flex
		width={"100%"}
		flexDirection={"column"}
		pt={[2, 2, 10]}
		mb={3}
		{...rest}
	>
		{/* VISUAL METER */}
		<Box
			bg={colors.darkGrey}
			width={"100%"}
			pt={4}
			css={`
				position: relative;
			`}
		>
			{/* AMOUNT RAISED BAR */}
			<Box
				bg={colors.green}
				width={`${(raised / goal) * 100}%`}
				pt={4}
				css={`
					position: absolute;
					top: 0;
					left: 0;
				`}
			/>
		</Box>
		<Flex justifyContent={"space-between"} pt={2}>
			<Flex flexDirection={"column"}>
				<Text as="p" fontWeight={600}>{`$${raised}`}</Text>
				<Text as="p">Raised</Text>
			</Flex>
			<Flex flexDirection={"column"} alignItems={"center"}>
				<Text as="p" fontWeight={600}>{`${donations}`}</Text>
				<Text as="p">Donations</Text>
			</Flex>
			<Flex flexDirection={"column"} alignItems={"flex-end"}>
				<Text as="p" fontWeight={600}>{`$${goal}`}</Text>
				<Text as="p">Goal</Text>
			</Flex>
		</Flex>
	</Flex>
);
