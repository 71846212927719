// Libraries
import { Flex, Text as TextBase } from "rebass";

// Component Imports
import { LayoutPadding } from "../utilities";

export const SubsectionText = ({ paragraphs, ...rest }) => {
	return (
		<LayoutPadding pb={[8, 8, 10]} {...rest}>
			<Flex
				width={"100%"}
				flexDirection={["column", "column", "column", "row"]}
			>
				{paragraphs.map((paragraph, i) => (
					<Flex
						key={i}
						width={[
							"100%",
							"100%",
							"100%",
							`${100 / paragraphs.length}`,
						]}
						mx={[
							0,
							0,
							0,
							`${paragraphs.length > 2 && i === 1 && 6}`,
						]}
						mt={[6, 6, 6, 0]}
					>
						<TextBase
							as="p"
							fontSize={[1, 1, 2]}
							fontWeight={100}
							css={`
								line-height: 22px;
							`}
							dangerouslySetInnerHTML={{ __html: paragraph }}
						/>
					</Flex>
				))}
			</Flex>
		</LayoutPadding>
	);
};
