// Component Imports
import { colors } from '../../styleguide/theme';
import { Flex, Text, Image, Button } from "rebass";

// UPDATE
// WILL NEED TO ADD STATE TO HIGHLIGHT / TRACK WHICH PAGE IS CURRENT
const PageButton = ({ num }) => {
    return (
        <Button disabled={disabled}>
            <Flex 
                width={['40px', '40px', '55px']}
                justifyContent='center'
                alignItems='center'
                css={`
                    height: 55px;
                    border: 1px solid ${colors.lightblue};
                    cursor: pointer;

                    &:hover {
                        background-color: ${colors.lightblue};
                        color: ${colors.white};
                    }

                    @media only screen and (max-width: 768px) {
                        height: 40px;     
                    }
                `}
            >
                <Text>{num}</Text>
            </Flex>
        </Button>
    )
}

export const PageNavigation = ({getMore, hasNext, hasPrev, getPrev}) => {
    return (
        <Flex 
            width={'100%'}
            justifyContent={['flex-start', 'flex-start', 'center']}
            alignItems='center'
        >
            <Flex 
                width={['100%', '100%', '55%', '30%']} 
                justifyContent={'space-between'}
            >
                <Button 
                    variant={'transparent'} 
                    disabled={!hasPrev}
                    onClick={() => getPrev()}
                >
                    <Flex 
                        css={`
                            cursor: ${hasPrev ? 'pointer' : 'not-allowed'};
                            position: relative;
                            font-weight: 300;
                        `}
                    >
                        {/* DISABLE BUTTON */}
                        {!hasPrev && <Flex 
                            width={'100%'} 
                            bg={'rgba(255,255,255,0.6)'} 
                            css={`
                                position: absolute; 
                                height: 100%;
                                z-index: 10;
                            `} 
                        />}
                        <Image  
                            width={'2.5rem'} 
                            src="/static/icons/misc/Arrow-Right-Blue.svg"
                        />
                        <Text ml={[4]} as='span'>Previous</Text>
                    </Flex>
                </Button>
                <Button 
                    variant={'transparent'} 
                    disabled={!hasNext}
                    onClick={() => getMore()}
                >
                    <Flex 
                        css={`
                            cursor: ${hasNext ? 'pointer' : 'not-allowed'};
                            position: relative;
                            font-weight: 300;
                        `}
                    >
                        {/* DISABLE BUTTON */}
                        {!hasNext && <Flex 
                            width={'100%'} 
                            bg={'rgba(255,255,255,0.6)'} 
                            css={`
                                position: absolute; 
                                height: 100%;
                                z-index: 10;
                            `} 
                        />}
                        <Text mr={[4]} as='span'>Next</Text>
                        <Image  
                            width={'2.5rem'} 
                            src="/static/icons/misc/Arrow-Left-Blue.svg"
                        />
                    </Flex>
                </Button>
            </Flex>
        </Flex>
    )
}