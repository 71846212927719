export const WaveOne = () => (
	<svg
		width="100%"
		height="75"
		viewBox="0 0 1436 70"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g style={{ mixBlendMode: "multiply", opacity: "0.5" }}>
			<path
				d="M1436 98H0.0651474L-100 11.1277C44.4274 34.3651 374.709 78.123 540.417 67.2551C747.552 53.6701 824.102 -29.6272 1069.26 11.1277C1265.39 43.7316 1395.47 82.6275 1436 98Z"
				fill="#008EFA"
				fillOpacity="0.3"
			/>
		</g>
	</svg>
);

export const WaveOneMobile = () => (
	<svg
		width="59"
		height="110%"
		viewBox="0 0 59 930"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g style={{ mixBlendMode: "multiply", opacity: "0.5" }}>
			<path
				d="M59.798 0.138516L60.7849 868.61L8.3122 929.191C22.2672 841.823 48.5056 642.034 41.8186 541.82C33.4599 416.551 -16.972 370.31 7.50857 222.006C27.093 103.364 50.5284 24.66 59.798 0.138516Z"
				fill="#008EFA"
				fillOpacity="0.3"
			/>
		</g>
	</svg>
);

export const WaveTwo = () => (
	<svg
		width="100%"
		height="63"
		viewBox="0 0 1440 40"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g style={{ mixBlendMode: "color-dodge" }}>
			<path
				d="M1763 25.8138L1729.05 70.0401L67.2495 70.341L-278 126C-229.09 79.0659 -22.4305 -5.0795 232.969 0.240721C521.828 6.25792 544.269 47.1748 781.341 58.9083C1018.41 70.6419 1150.18 58.0058 1412.57 25.8138C1622.48 0.0602169 1733.65 15.0831 1763 25.8138Z"
				fill="url(#paint0_linear)"
				fillOpacity="0.5"
			/>
		</g>
		<defs>
			<linearGradient
				id="paint0_linear"
				x1="2091.56"
				y1="100.427"
				x2="84.3825"
				y2="-476.496"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#49185C" />
				<stop offset="1" stopColor="#0071C8" stopOpacity="0.3" />
			</linearGradient>
		</defs>
	</svg>
);

export const WaveTwoMobile = () => (
	<svg
		width="50"
		height="100%"
		viewBox="0 0 45 1158"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g style={{ mixBlendMode: "color-dodge" }}>
			<path
				d="M25.1931 -10.7485L69.584 8.62757L70.9664 959.537L127.029 1157.03C79.9121 1129.1 -4.63875 1010.94 0.53253 864.789C6.38128 699.493 47.4154 686.605 59.0325 550.935C70.6497 415.266 57.8872 339.879 25.4209 189.772C-0.552089 69.6867 14.4469 6.05607 25.1931 -10.7485Z"
				fill="#0071C8"
				fillOpacity="0.4"
			/>
		</g>
		<defs>
			<linearGradient
				id="paint0_linear"
				x1="99.8331"
				y1="-198.842"
				x2="-97.3078"
				y2="1012.27"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#008EFA" />
				<stop offset="1" stopColor="#0071C8" stopOpacity="0.3" />
			</linearGradient>
		</defs>
	</svg>
);
