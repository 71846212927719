import { Text as TextBase } from "rebass";

export const Text = ({ as, css, children, ...rest }) => {
	const attributes = {
		h1: {
			fontSize: [5],
			css: `
                line-height: 1; 
                text-transform: uppercase;
                letter-spacing: 1.8px;
            `,
		},
		h2: {
			fontSize: [6, 6, 8],
			css: `
                line-height: 1; 
                text-transform: uppercase; 
                letter-spacing: 1.8px;
                
            `,
		},
		h3: {
			fontSize: [4],
			fontWeight: 100,
			css: `
                line-height: 1; 
                text-transform: uppercase;
                letter-spacing: 1.44px;
                
            `,
		},
		h4: {
			fontSize: [2],
			css: `
                line-height: 1; 
                text-transform: uppercase;
                letter-spacing: 1.44px;
                
            `,
		},
		h5: {
			fontSize: [3],
			css: `
                line-height: 1;  
                text-transform: uppercase; 
                letter-spacing: 1.44px;
                
            `,
		},
		h6: {
			fontSize: [1],
			fontWeight: 100,
			css: `
                line-height:22px;
            `,
		},
		p: {
			fontSize: ["1.1rem"],
			fontWeight: 100,
			css: `
                line-height: 22px;
            `,
		},
		span: {
			fontSize: [1],
			fontWeight: 100,
			css: `
                line-height: 22px;
                
            `,
		},
	};

	return (
		<TextBase
			as={as}
			attributes={attributes}
			{...attributes[as]}
			css={`
				${(props) => props.attributes[props.as].css}
				${(props) => (props.styling && props.styling) || ""}
			`}
			{...rest}
		>
			{children}
		</TextBase>
	);
};
