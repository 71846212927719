// Libraries
import React from "react";
import { Flex, Box } from "rebass";

// Component Imports
import { Section } from "./Section";
import { Hide } from "../../utilities";

export const Jumbotron = ({ image, children }) => {
	return (
		<Section
			css={`
				background-image: url(${image
					? image
					: "/static/test-images/jumbo.jpeg"});
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
			`}
		>
			<Flex
				flexDirection={"column"}
				justifyContent={"flex-end"}
				mb={["0", "0", "0", 9]}
				width={1}
			>
				<Hide
					width={"100%"}
					hide={[1, 1, 1, 0]}
					css={`
						height: 100%;
					`}
				>
					<Box
						className={"mobile--image"}
						css={`
							background-image: url(${image
								? image
								: "/static/test-images/Day1_259-For-Web-Social-Media-1-1024x483@2x.png"});
							background-repeat: no-repeat;
							background-size: cover;
							background-position: center;
							min-height: 40vw;
							height: 100%;
							width: 100%;

							@media only screen and (max-width: 768px) {
								min-height: 50vw;
							}

							@media only screen and (max-width: 500px) {
								min-height: 80vw;
							}
						`}
					/>
				</Hide>
				{children}
			</Flex>
		</Section>
	);
};
