import { Text, Flex } from "rebass";
import { default as NextBase } from "next/link";

import { colors } from "../../styleguide/theme";

export const HoverOverlay = (props) => {
	return (
		<NextBase passHref href={props.href}>
			<Flex
				className="card"
				width={
					props.width ? props.width : ["100%", "100%", "50%", "33.3%"]
				}
				flexDirection={"column"}
				justifyContent={"flex-end"}
				mt={[0, 0, 6, 0]}
				css={`
					min-height: 425px;
					min-width: 300px;
					height: ${props.height ? props.height : "35vw"};
					transition: height 0.3s linear;
					position: relative;
					background-image: url(${props.image});
					background-position: center;
					background-size: cover;
					box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
						0 3px 6px rgba(0, 0, 0, 0.23);
					cursor: pointer;
					&:hover {
						box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
							0 10px 10px rgba(0, 0, 0, 0.22);
						transform: scale(1.03);
						z-index: 1;
					}
					&:hover .card__content--hover {
						background-color: ${colors.hoverGradient};
						padding-top: 1.5rem;
						height: ${props.fullHover ? "100%" : "auto"};
					}
					&:hover .card__content--hover > p {
						display: block;
					}

					&:hover .card__content--text-shadow {
						text-shadow: 0px 2px 2px ${colors.lightblue};
					}
				`}
			>
				<Flex
					className="card__content--hover"
					flexDirection={"column"}
					alignItems={"flex-start"}
					justifyContent={"flex-end"}
					width={"100%"}
					p={"1.5rem"}
					pb={"0.8rem"}
					color={"#FFF"}
					css={`
						height: 50%;
						background-image: linear-gradient(
							rgba(255, 255, 255, 0),
							${colors.hoverGradient}
						);
						transition: 0.3s;
					`}
				>
					<Text
						fontSize={[1, 1, 2]}
						as="h4"
						mb={"0.5rem"}
						css={`
							text-transform: uppercase;
						`}
					>
						{props.title}
					</Text>
					{props.children}
				</Flex>
			</Flex>
		</NextBase>
	);
};
