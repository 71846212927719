import styled from "styled-components";
import { Image } from "rebass";
import {colors} from '../../styleguide/theme'

export const Icon = props => (
    <Image
        css={`
            // Prevent users from selected and browsers from making icons ino buttons.
            user-select: none;
            -webkit-appearance: initial;
        `}
        {...props}
    />
);

export const SVG = styled.svg`
user-select: none;
fill: ${props => props.fill || 'none'};
stroke: ${props => props.stroke || 'white'};
stroke-width: 2px;
`

export const IconFlip = styled(props => <Icon {...props}/>)`
    ${props => (props.flipped ? `transform: rotate(180deg);` : ``)}
`;

export const SVGFlip = styled(props => <SVG {...props} />)`
    ${props => (props.flipped ? `transform: rotate(180deg);` : ``)}
`;
