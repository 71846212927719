import React from "react";

import { Box, Flex } from "rebass";
import { Button } from "../inputs/Button";

export class Hamburger extends React.Component {
	state = {
		isOpen: this.props.isOpen,
	};
	render() {
		let { isOpen } = this.props;
		return (
			<>
				<Button
					variant="hidden"
					px={1}
					py={0}
					m={0}
					name="menu"
					css={`
						height: 35px;
						border-radius: 0;
						${isOpen ? "background-color: transparent;" : "black;"}
						z-index: 10;
					`}
					onClick={() => {
						this.setState({ isOpen: !isOpen });
						this.props.onClick(!isOpen);
					}}
				>
					<Flex
						width={5}
						mx={2}
						flexDirection="column"
						width={"20px"}
					>
						<Box
							my={isOpen ? "0px" : "2px"}
							css={`
								height: 1px;
								width: 100%;
								transition: 250ms ease;
								${isOpen ? `transform: rotate(45deg);` : ``}
							`}
							backgroundColor="black"
						/>
						{!isOpen && (
							<Box
								my={"2px"}
								css="height: 1px; width: 100%;"
								backgroundColor="black"
							/>
						)}
						<Box
							my={isOpen ? "-2px" : "2px"}
							css={`
								height: 1px;
								width: 100%;
								transition: 250ms ease;
								${isOpen ? `transform: rotate(-45deg);` : ``}
							`}
							backgroundColor="black"
						/>
					</Flex>
				</Button>
			</>
		);
	}
}
