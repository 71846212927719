// Libraries
import { Flex } from "rebass";

export const HeroStripe = (props) => (
    <Flex
        mt={[13,13,'10rem','15rem']}
        {...props}
    >    
        <Flex
            bg={props.bg}
            width={'100%'}
            mb={[0,0,4]}
            css={`   
                z-index: 10;
            `}
        >
            {props.children}
        </Flex>
    </Flex>
)