import { default as NextBase } from "next/link";
import { Link as Base } from "rebass";
import styled from "styled-components";
import { colors } from "../../styleguide/theme";
import { color, space, typography } from "styled-system";
import { Button } from "rebass";

const StyledBase = styled.a`
	text-decoration: none;

	color: ${(props) =>
		props.colors || props.theme.links[props.variant || "primary"].color};
	&:visited {
		color: ${(props) =>
			props.theme.links[props.variant || "primary"].visited};
	}
	&:hover {
		color: ${(props) =>
			props.theme.links[props.variant || "primary"].hover.color};
		font-weight: ${(props) =>
			props.theme.links[props.variant || "primary"].hover.fontWeight};
		border-bottom: ${(props) =>
			props.theme.links[props.variant || "primary"].hover.borderBottom};
	}

	${space}
	${typography}
`;

const StyledButtonBase = styled((props) => <Button {...props} />)`
	text-decoration: none;
	/* 
    color: ${(props) =>
		props.colors || props.theme.links[props.variant || "primary"].color};
    &:visited {
        color: ${(props) =>
		props.theme.links[props.variant || "primary"].visited};
    }
    &:hover {
        color: ${(props) =>
		props.theme.links[props.variant || "primary"].hover};
    }

    ${space}
    ${typography} */
`;

export const Link = (props) => (
	<NextBase passHref href={props.href}>
		<StyledBase {...props}>{props.children}</StyledBase>
	</NextBase>
);

export const NavLink = (props) => (
	<NextBase passHref href={props.href}>
		<StyledBase
			{...props}
			active={props.active}
			css={`
				${(props) => (props.active ? "font-weight: 600;" : "")}
			`}
		>
			{props.children}
		</StyledBase>
	</NextBase>
);

export const ButtonLink = (props) => (
	<NextBase passHref href={props.href}>
		<StyledButtonBase
			data-testid={props.testID}
			type={props.type}
			{...props}
		>
			{props.children}
		</StyledButtonBase>
	</NextBase>
);

export const NavButtonLink = (props) => (
	<NextBase passHref href={props.href}>
		<StyledButtonBase
			{...props}
			active={props.active}
			css={`
				${(props) => (props.active ? "font-weight: 600;" : "")}
			`}
		>
			{props.children}
		</StyledButtonBase>
	</NextBase>
);
