// Libraries
import { Flex, Image, Text as TextBase, Box, Button } from "rebass";
import { default as NextBase } from "next/link";

// Component Imports
import { LayoutPadding } from "../utilities";
import { Text } from "./Text";

const LoadContentBtn = ({ rotate, href, title }) => (
	<Button
		disabled={!href.query.id}
		variant={"transparent"}
		css={`
			cursor: ${!href.query.id ? "not-allowed" : "pointer"};
		`}
	>
		<Flex
			css={`
				position: relative;
			`}
		>
			{/* DISABLE BUTTON */}
			{!href.query.id && (
				<Flex
					width={"100%"}
					bg={"rgba(255,255,255,0.6)"}
					css={`
						position: absolute;
						height: 100%;
					`}
				/>
			)}
			<NextBase passHref href={href}>
				<Flex
					justifyContent={["center", "center", "flex-start"]}
					flexDirection={`${rotate ? "row-reverse" : "row"}`}
					alignItems={"center"}
				>
					<Flex ml={rotate ? [4] : "0"} mr={rotate ? "0" : [4]}>
						<Image
							width={"3rem"}
							src={`/static/icons/misc/Arrow-${
								rotate ? "Right" : "Left"
							}-Blue.svg`}
						/>
					</Flex>
					<Text
						as="p"
						styling={`
							text-transform: uppercase;
						`}
					>
						{title}
					</Text>
				</Flex>
			</NextBase>
		</Flex>
	</Button>
);

export const Paragraph = ({
	showNavigation,
	body,
	quote,
	previousHref,
	nextHref,
	disabled,
}) => (
	<LayoutPadding py={[10, 10, 12]}>
		<Flex
			width={"100%"}
			flexDirection={["column"]}
			css={`
				position: relative;
			`}
		>
			{/* ONLY SHOW WHEN NEEDED - PREV + NEXT BUTTON */}
			{showNavigation && (
				<Flex
					width={["100%"]}
					justifyContent={"space-between"}
					mb={6}
					css={`
						@media only screen and (min-width: 1055px) {
							position: absolute;
						}
					`}
				>
					<LoadContentBtn
						title={"previous"}
						rotate
						href={previousHref || "/"}
						disabled={disabled}
					/>
					<LoadContentBtn
						title={"next"}
						href={nextHref || "/"}
						disabled={disabled}
					/>
				</Flex>
			)}
			{/* CONTENT */}
			<Flex
				width={"100%"}
				justifyContent={"center"}
				alignItems={"center"}
				flexDirection={"column"}
			>
				<Flex
					width={["100%", "100%", "100%", "75%"]}
					px={["0", "0", "0", 10]}
				>
					<TextBase
						as="div"
						fontSize={[1, 1, 2]}
						styling={`
                            line-height: 24px;
                        `}
						dangerouslySetInnerHTML={{ __html: body }}
					/>
				</Flex>
				{/* Quote */}
				{quote && (
					<Box
						width={["100%", "100%", "100%", "75%"]}
						mt={4}
						px={["0", "0", "0", 10]}
						py={[6, 6, 8, 8]}
						css={`
							position: relative;
						`}
					>
						<Image
							width={["6rem", "6rem", "8rem"]}
							src="/static/icons/misc/Quotation.svg"
							css={`
								position: absolute;
								z-index: -1;
								top: 10px;
								left: 0;
							`}
						/>
						<Text
							as="p"
							fontWeight={"bold"}
							ml={[8]}
							fontSize={[3]}
							styling={`
								font-style: italic;
							`}
						>
							{quote}
						</Text>
					</Box>
				)}
			</Flex>
		</Flex>
	</LayoutPadding>
);
