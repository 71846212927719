import styled from "styled-components";
import { Box, Flex, Text, Image } from "rebass";
import {
	space,
	color,
	flexbox,
	typography,
	border,
	position,
	layout,
	background
} from "styled-system";
import { Button } from "../inputs/Button";
import { Icon, IconFlip } from "../icons";
import { colors } from "../../styleguide/theme";
import Downshift from "downshift";

export const InputLabel = styled.label`
	font-weight: 600;
	text-align: left;
	user-select: none;
	${typography}
	${space}
`;

export const InputError = styled.label`
    user-select: none;
    min-height: 20px;
    margin-top: 5px;
    margin-left: 20px;
    ${typography}
    ${space}
    ${color}
`;

export const Input = styled.input`
    display: flex;
    align-items: center;

    margin: 0;

    border: none;
    border-bottom: 2px solid ${colors.black};
    border-radius: ${props =>
		props.borderRadius ? props.borderRadius : "0px"};

    text-transform: ${props =>
		props.textTransform ? props.textTransform : ""};

    ::placeholder { 
        color: ${props =>
			props.placeholderColor
				? props.placeholderColor
				: colors.black + ".0"};
        opacity: 1;
    } 
    

    ${props =>
		props.error &&
		`
        background: ${colors.errorPink}; 
        border-bottom: 2px solid ${colors.errorRed};
        color: ${colors.errorRed};
    `}
    
    ${props =>
		!props.noFocus &&
		`:focus {
        outline: none;
        background-color: #008EFA24;
        /* This stops the focus border from pushing things down */
        /* margin-bottom: calc(.75em); */
    }`}

    /* Styled-System */
    ${color}
    ${space}
    ${typography}
    ${border}
    ${position}
    ${layout}
`;

export const TextArea = styled(props => <Input {...props}/>).attrs({
	as: "textarea"
})`
	min-height: 100px;
`;

// styled.textarea`

// `
