// Libraries
import { Flex, Image, Text as TextBase } from "rebass";

// Components
import { CallToAction,} from '../../inputs'
import {LayoutPadding} from '../../utilities'
import { Text } from "../../info";
import { colors } from "../../../styleguide/theme";

export const SplitContent = ({
  title,
  subTitle,
  paragraph,
  flexDirection,
  titleCTA,
  colorCTA,
  replaceCTA,
  href,
  image,
  replaceImage,
  split,
  ...rest
}) => (
  <Flex
    width={"100%"}
    flexDirection={
      flexDirection ? flexDirection : ["column", "column", "column", "row"]
    }
    bg={colors.offwhite}
  >
    {/* CONTENT SECTION */}
    <Flex
      width={split ? split[0] : ["100%", "100%", "100%", "50%"]}
      flexDirection={"column"}
      justifyContent={"center"}
      py={[9, 9, 11]}
      {...rest}
    >
      {/* ONLY SHOWS CONTENT IF INFO PROVIDED */}
      <LayoutPadding py={["0", "0", 6]}>
        {/* TITLE */}
        {title && (
          <Text as="h2" mb={[7, 7, 6]}>
            {title}
          </Text>
        )}

        {/* SUBTITLE */}
        {subTitle && (
          <Text as="h3" mb={[5, 5, 7]}>
            {subTitle}
          </Text>
        )}

        {/* PARAGRAPH */}
        {paragraph && (
          <TextBase 
            as="div" mb={[8, 8, 10]} 
            fontSize={[1,1,2]}
            fontWeight={100}
            css={`
                line-height: 22px;
            `}
            dangerouslySetInnerHTML={{__html: paragraph}} 
          />
        )}

        {/* CTA  */}
        {replaceCTA
          ? replaceCTA()
          : titleCTA && (
            <Flex>
            <CallToAction
                title={titleCTA}
                subColor={colorCTA}
                href={href}
            />
            </Flex>
        )}
      </LayoutPadding>
    </Flex>
    {/* IMAGE SECTION*/}
    {replaceImage ? (
      replaceImage()
    ) : (
      <Flex
        width={split ? split[1] : ["100%", "100%", "100%", "50%"]}
        justifyContent={"center"}
        css={`
                background-image: url("${image}");
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;

                @media only screen and (max-width: 550px) {
                    height: 350px;
                }

                @media only screen and (min-width: 550px) and (max-width: 1055px) {
                    height: auto;
                    min-height: 550px;
                }
            `}
        {...rest}
      />
    )}
  </Flex>
);
