import React from "react";
import { animateScroll } from "react-scroll";
import { Flex, Image, Text, Button } from "rebass";
import { Link } from "../../links";
import { OneLineForm, CallToAction } from "../../inputs";
import { LayoutPadding } from "../../utilities";
import { Loading } from "../../trackers";

import { colors } from "../../../styleguide/theme";
import { useQuery, gql } from "@apollo/client";

const CrazyComponent = ({}) => {
	return (
		<div
			dangerouslySetInnerHTML={{
				__html: `
				<style>
				#_form_35_ { font-size:14px; line-height:1.6; font-family:arial, helvetica, sans-serif; margin:0; background: transparent; }
				#_form_35_ * { outline:0; }
				._form_hide { display:none; visibility:hidden; }
				._form_show { display:block; visibility:visible; }
				#_form_35_._form-top { top:0; }
				#_form_35_._form-bottom { bottom:0; }
				#_form_35_._form-left { left:0; }
				#_form_35_._form-right { right:0; }
				#_form_35_ input[type="text"],#_form_35_ input[type="date"],#_form_35_ textarea { padding:6px; height:auto; border:#979797 1px solid; border-radius:4px; color:#000 !important; font-size:14px; -webkit-box-sizing:border-box; -moz-box-sizing:border-box; box-sizing:border-box; }
				#_form_35_ textarea { resize:none; }
				#_form_35_ ._submit { -webkit-appearance:none; cursor:pointer; font-family:arial, sans-serif; font-size:14px; text-align:center; background:#333 !important; border:0 !important; -moz-border-radius:4px !important; -webkit-border-radius:4px !important; border-radius:4px !important; color:#fff !important; padding:10px !important; }
				#_form_35_ ._close-icon { cursor:pointer; background-image:url('https://d226aj4ao1t61q.cloudfront.net/esfkyjh1u_forms-close-dark.png'); background-repeat:no-repeat; background-size:14.2px 14.2px; position:absolute; display:block; top:11px; right:9px; overflow:hidden; width:16.2px; height:16.2px; }
				#_form_35_ ._close-icon:before { position:relative; }
				#_form_35_ ._form-body { margin-bottom:30px; }
				#_form_35_ ._form-image-left { width:150px; float:left; }
				#_form_35_ ._form-content-right { margin-left:164px; }
				#_form_35_ ._form-branding { color:#fff; font-size:10px; clear:both; text-align:left; margin-top:30px; font-weight:100; }
				#_form_35_ ._form-branding ._logo { display:block; width:130px; height:14px; margin-top:6px; background-image:url('https://d226aj4ao1t61q.cloudfront.net/hh9ujqgv5_aclogo_li.png'); background-size:130px auto; background-repeat:no-repeat; }
				#_form_35_ ._form-label,#_form_35_ ._form_element ._form-label { font-weight:bold; margin-bottom:5px; display:block; }
				#_form_35_._dark ._form-branding { color:#333; }
				#_form_35_._dark ._form-branding ._logo { background-image:url('https://d226aj4ao1t61q.cloudfront.net/jftq2c8s_aclogo_dk.png'); }
				#_form_35_ ._form_element { position:relative; margin-bottom:10px; font-size:0; max-width:100%; }
				#_form_35_ ._form_element * { font-size:14px; }
				#_form_35_ ._form_element._clear { clear:both; width:100%; float:none; }
				#_form_35_ ._form_element._clear:after { clear:left; }
				#_form_35_ ._form_element input[type="text"],#_form_35_ ._form_element input[type="date"],#_form_35_ ._form_element select,#_form_35_ ._form_element textarea:not(.g-recaptcha-response) { display:block; width:100%; -webkit-box-sizing:border-box; -moz-box-sizing:border-box; box-sizing:border-box; }
				#_form_35_ ._field-wrapper { position:relative; }
				#_form_35_ ._inline-style { float:left; }
				#_form_35_ ._inline-style input[type="text"] { width:150px; }
				#_form_35_ ._inline-style:not(._clear) + ._inline-style:not(._clear) { margin-left:20px; }
				#_form_35_ ._form_element img._form-image { max-width:100%; }
				#_form_35_ ._clear-element { clear:left; }
				#_form_35_ ._full_width { width:100%; }
				#_form_35_ ._form_full_field { display:block; width:100%; margin-bottom:10px; }
				#_form_35_ input[type="text"]._has_error,#_form_35_ textarea._has_error { border:#f37c7b 1px solid; }
				#_form_35_ input[type="checkbox"]._has_error { outline:#f37c7b 1px solid; }
				#_form_35_ ._error { display:block; position:absolute; font-size:14px; z-index:10000001; }
				#_form_35_ ._error._above { padding-bottom:4px; bottom:39px; right:0; }
				#_form_35_ ._error._below { padding-top:4px; top:100%; right:0; }
				#_form_35_ ._error._above ._error-arrow { bottom:0; right:15px; border-left:5px solid transparent; border-right:5px solid transparent; border-top:5px solid #f37c7b; }
				#_form_35_ ._error._below ._error-arrow { top:0; right:15px; border-left:5px solid transparent; border-right:5px solid transparent; border-bottom:5px solid #f37c7b; }
				#_form_35_ ._error-inner { padding:8px 12px; background-color:#f37c7b; font-size:14px; font-family:arial, sans-serif; color:#fff; text-align:center; text-decoration:none; -webkit-border-radius:4px; -moz-border-radius:4px; border-radius:4px; }
				#_form_35_ ._error-inner._form_error { margin-bottom:5px; text-align:left; }
				#_form_35_ ._button-wrapper ._error-inner._form_error { position:static; }
				#_form_35_ ._error-inner._no_arrow { margin-bottom:10px; }
				#_form_35_ ._error-arrow { position:absolute; width:0; height:0; }
				#_form_35_ ._error-html { margin-bottom:10px; }
				.pika-single { z-index:10000001 !important; }
				@media all and (min-width:320px) and (max-width:667px) { ::-webkit-scrollbar { display:none; }
				#_form_35_ { margin:0; width:100%; min-width:100%; max-width:100%; box-sizing:border-box; }
				#_form_35_ * { -webkit-box-sizing:border-box; -moz-box-sizing:border-box; box-sizing:border-box; font-size:1em; }
				#_form_35_ ._form-content { margin:0; width:100%; background: transparent; }
				#_form_35_ ._form-inner { display:block; min-width:100%; }
				#_form_35_ ._form-title,#_form_35_ ._inline-style { margin-top:0; margin-right:0; margin-left:0; }
				#_form_35_ ._form-title { font-size:1.2em; }
				#_form_35_ ._form_element { margin:0 0 20px; padding:0; width:100%; }
				#_form_35_ ._form-element,#_form_35_ ._inline-style,#_form_35_ input[type="text"],#_form_35_ label,#_form_35_ p,#_form_35_ textarea:not(.g-recaptcha-response) { float:none; display:block; width:100%; }
				#_form_35_ ._row._checkbox-radio label { display:inline; }
				#_form_35_ ._row,#_form_35_ p,#_form_35_ label { margin-bottom:0.7em; width:100%; }
				#_form_35_ ._row input[type="checkbox"],#_form_35_ ._row input[type="radio"] { margin:0 !important; vertical-align:middle !important; }
				#_form_35_ ._row input[type="checkbox"] + span label { display:inline; }
				#_form_35_ ._row span label { margin:0 !important; width:initial !important; vertical-align:middle !important; }
				#_form_35_ ._form-image { max-width:100%; height:auto !important; }
				#_form_35_ input[type="text"] { padding-left:10px; padding-right:10px; font-size:16px; line-height:1.3em; -webkit-appearance:none; }
				#_form_35_ input[type="radio"],#_form_35_ input[type="checkbox"] { display:inline-block; width:1.3em; height:1.3em; font-size:1em; margin:0 0.3em 0 0; vertical-align:baseline; }
				#_form_35_ button[type="submit"] { padding:20px; font-size:1.5em; }
				#_form_35_ ._inline-style { margin:20px 0 0 !important; }
				}
				#_form_35_ { 
					position:relative; 
					text-align:left;
					padding:20px; 
					-webkit-box-sizing:border-box; 
					-moz-box-sizing:border-box; 
					box-sizing:border-box; 
					*zoom:1; 
					background:#fff !important; border:0px solid #b0b0b0 !important; width:500px; -moz-border-radius:0px !important; -webkit-border-radius:0px !important; border-radius:0px !important; color:#000 !important; }
				#_form_35_ ._form-title { font-size:22px; line-height:22px; font-weight:600; margin-bottom:0; }
				#_form_35_:before,#_form_35_:after { content:" "; display:table; }
				#_form_35_:after { clear:both; }
				#_form_35_._inline-style { width:auto; display:inline-block; }
				#_form_35_._inline-style input[type="text"],#_form_35_._inline-style input[type="date"] { padding:10px 12px; }
				#_form_35_._inline-style button._inline-style { position:relative; top:27px; }
				#_form_35_._inline-style p { margin:0; }
				#_form_35_._inline-style ._button-wrapper { position:relative; margin:27px 12.5px 0 20px; }
				#_form_35_ ._form-thank-you { position:relative; left:0; right:0; text-align:center; font-size:18px; }
				@media all and (min-width:320px) and (max-width:667px) { #_form_35_._inline-form._inline-style ._inline-style._button-wrapper { margin-top:20px !important; margin-left:0 !important; }
				}
			   </style>
			   <form style="background-color: transparent !important;" method="POST" action="https://reena97786.activehosted.com/proc.php" id="_form_35_" class="_form _form_35 _inline-form  _dark" novalidate>
				 <input type="hidden" name="u" value="35" />
				 <input type="hidden" name="f" value="35" />
				 <input type="hidden" name="s" />
				 <input type="hidden" name="c" value="0" />
				 <input type="hidden" name="m" value="0" />
				 <input type="hidden" name="act" value="sub" />
				 <input type="hidden" name="v" value="2" />
				 <div class="_form-content">
				   <div class="_form_element _x43150071 _full_width _clear" >
				   </div>
				   <div class="_form_element _x61033592 _full_width _clear" >
				   </div>
				   <div class="_form_element _x23005840 _full_width " >
				   </div>
				   <div class="_button-wrapper _full_width">
				   </div>
				   <div class="_clear-element">
				   </div>
				 </div>
				 <div style="color: #FFF !important;" class="_form-thank-you" style="display:none;">
				 </div>
			   </form><script type="text/javascript">
			   window.cfields = [];
			   window._show_thank_you = function(id, message, trackcmp_url) {
				 var form = document.getElementById('_form_' + id + '_'), thank_you = form.querySelector('._form-thank-you');
				 form.querySelector('._form-content').style.display = 'none';
				 thank_you.innerHTML = message;
				 thank_you.style.display = 'block';
				 if (typeof(trackcmp_url) != 'undefined' && trackcmp_url) {
				   // Site tracking URL to use after inline form submission.
				   _load_script(trackcmp_url);
				 }
				 if (typeof window._form_callback !== 'undefined') window._form_callback(id);
			   };
			   window._show_error = function(id, message, html) {
				 var form = document.getElementById('_form_' + id + '_'), err = document.createElement('div'), button = form.querySelector('button'), old_error = form.querySelector('._form_error');
				 if (old_error) old_error.parentNode.removeChild(old_error);
				 err.innerHTML = message;
				 err.className = '_error-inner _form_error _no_arrow';
				 var wrapper = document.createElement('div');
				 wrapper.className = '_form-inner';
				 wrapper.appendChild(err);
				//  button.parentNode.insertBefore(wrapper, button);
				//  document.querySelector('[id^="_form"][id$="_submit"]').disabled = false;
				 if (html) {
				   var div = document.createElement('div');
				   div.className = '_error-html';
				   div.innerHTML = html;
				   err.appendChild(div);
				 }
			   };
			   window._load_script = function(url, callback) {
				 var head = document.querySelector('head'), script = document.createElement('script'), r = false;
				 script.type = 'text/javascript';
				 script.charset = 'utf-8';
				 script.src = url;
				 if (callback) {
				   script.onload = script.onreadystatechange = function() {
					 if (!r && (!this.readyState || this.readyState == 'complete')) {
					   r = true;
					   callback();
					 }
				   };
				 }
				 head.appendChild(script);
			   };
			   (function() {
				 if (window.location.search.search("excludeform") !== -1) return false;
				 var getCookie = function(name) {
				   var match = document.cookie.match(new RegExp('(^|; )' + name + '=([^;]+)'));
				   return match ? match[2] : null;
				 }
				 var setCookie = function(name, value) {
				   var now = new Date();
				   var time = now.getTime();
				   var expireTime = time + 1000 * 60 * 60 * 24 * 365;
				   now.setTime(expireTime);
				   document.cookie = name + '=' + value + '; expires=' + now + ';path=/';
				 }
					 var addEvent = function(element, event, func) {
				   if (element.addEventListener) {
					 element.addEventListener(event, func);
				   } else {
					 var oldFunc = element['on' + event];
					 element['on' + event] = function() {
					   oldFunc.apply(this, arguments);
					   func.apply(this, arguments);
					 };
				   }
				 }
				 var _removed = false;
				 var form_to_submit = document.getElementById('_form_35_');
				 var allInputs = form_to_submit.querySelectorAll('input, select, textarea'), tooltips = [], submitted = false;
			   
				 var getUrlParam = function(name) {
				   var regexStr = '[\?&]' + name + '=([^&#]*)';
				   var results = new RegExp(regexStr, 'i').exec(window.location.href);
				   return results != undefined ? decodeURIComponent(results[1]) : false;
				 };
			   
				 for (var i = 0; i < allInputs.length; i++) {
				   var regexStr = "field\\[(\\d+)\\]";
				   var results = new RegExp(regexStr).exec(allInputs[i].name);
				   if (results != undefined) {
					 allInputs[i].dataset.name = window.cfields[results[1]];
				   } else {
					 allInputs[i].dataset.name = allInputs[i].name;
				   }
				   var fieldVal = getUrlParam(allInputs[i].dataset.name);
			   
				   if (fieldVal) {
					 if (allInputs[i].dataset.autofill === "false") {
					   continue;
					 }
					 if (allInputs[i].type == "radio" || allInputs[i].type == "checkbox") {
					   if (allInputs[i].value == fieldVal) {
						 allInputs[i].checked = true;
					   }
					 } else {
					   allInputs[i].value = fieldVal;
					 }
				   }
				 }
			   
				 var remove_tooltips = function() {
				   for (var i = 0; i < tooltips.length; i++) {
					 tooltips[i].tip.parentNode.removeChild(tooltips[i].tip);
				   }
				   tooltips = [];
				 };
				 var remove_tooltip = function(elem) {
				   for (var i = 0; i < tooltips.length; i++) {
					 if (tooltips[i].elem === elem) {
					   tooltips[i].tip.parentNode.removeChild(tooltips[i].tip);
					   tooltips.splice(i, 1);
					   return;
					 }
				   }
				 };
				 var create_tooltip = function(elem, text) {
				   var tooltip = document.createElement('div'), arrow = document.createElement('div'), inner = document.createElement('div'), new_tooltip = {};
				   if (elem.type != 'radio' && elem.type != 'checkbox') {
					 tooltip.className = '_error';
					 arrow.className = '_error-arrow';
					 inner.className = '_error-inner';
					 inner.innerHTML = text;
					 tooltip.appendChild(arrow);
					 tooltip.appendChild(inner);
					 elem.parentNode.appendChild(tooltip);
				   } else {
					 tooltip.className = '_error-inner _no_arrow';
					 tooltip.innerHTML = text;
					 elem.parentNode.insertBefore(tooltip, elem);
					 new_tooltip.no_arrow = true;
				   }
				   new_tooltip.tip = tooltip;
				   new_tooltip.elem = elem;
				   tooltips.push(new_tooltip);
				   return new_tooltip;
				 };
				 var resize_tooltip = function(tooltip) {
				   var rect = tooltip.elem.getBoundingClientRect();
				   var doc = document.documentElement, scrollPosition = rect.top - ((window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0));
				   if (scrollPosition < 40) {
					 tooltip.tip.className = tooltip.tip.className.replace(/ ?(_above|_below) ?/g, '') + ' _below';
				   } else {
					 tooltip.tip.className = tooltip.tip.className.replace(/ ?(_above|_below) ?/g, '') + ' _above';
				   }
				 };
				 var resize_tooltips = function() {
				   if (_removed) return;
				   for (var i = 0; i < tooltips.length; i++) {
					 if (!tooltips[i].no_arrow) resize_tooltip(tooltips[i]);
				   }
				 };
				 var validate_field = function(elem, remove) {
				   var tooltip = null, value = elem.value, no_error = true;
				   remove ? remove_tooltip(elem) : false;
				   if (elem.type != 'checkbox') elem.className = elem.className.replace(/ ?_has_error ?/g, '');
				   if (elem.getAttribute('required') !== null) {
					 if (elem.type == 'radio' || (elem.type == 'checkbox' && /any/.test(elem.className))) {
					   var elems = form_to_submit.elements[elem.name];
					   if (!(elems instanceof NodeList || elems instanceof HTMLCollection) || elems.length <= 1) {
						 no_error = elem.checked;
					   }
					   else {
						 no_error = false;
						 for (var i = 0; i < elems.length; i++) {
						   if (elems[i].checked) no_error = true;
						 }
					   }
					   if (!no_error) {
						 tooltip = create_tooltip(elem, "Please select an option.");
					   }
					 } else if (elem.type =='checkbox') {
					   var elems = form_to_submit.elements[elem.name], found = false, err = [];
					   no_error = true;
					   for (var i = 0; i < elems.length; i++) {
						 if (elems[i].getAttribute('required') === null) continue;
						 if (!found && elems[i] !== elem) return true;
						 found = true;
						 elems[i].className = elems[i].className.replace(/ ?_has_error ?/g, '');
						 if (!elems[i].checked) {
						   no_error = false;
						   elems[i].className = elems[i].className + ' _has_error';
						   err.push("Checking %s is required".replace("%s", elems[i].value));
						 }
					   }
					   if (!no_error) {
						 tooltip = create_tooltip(elem, err.join('<br/>'));
					   }
					 } else if (elem.tagName == 'SELECT') {
					   var selected = true;
					   if (elem.multiple) {
						 selected = false;
						 for (var i = 0; i < elem.options.length; i++) {
						   if (elem.options[i].selected) {
							 selected = true;
							 break;
						   }
						 }
					   } else {
						 for (var i = 0; i < elem.options.length; i++) {
						   if (elem.options[i].selected && !elem.options[i].value) {
							 selected = false;
						   }
						 }
					   }
					   if (!selected) {
						 elem.className = elem.className + ' _has_error';
						 no_error = false;
						 tooltip = create_tooltip(elem, "Please select an option.");
					   }
					 } else if (value === undefined || value === null || value === '') {
					   elem.className = elem.className + ' _has_error';
					   no_error = false;
					   tooltip = create_tooltip(elem, "This field is required.");
					 }
				   }
				   if (no_error && elem.name == 'email') {
					 if (!value.match(/^[\+_a-z0-9-'&=]+(\.[\+_a-z0-9-']+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i)) {
					   elem.className = elem.className + ' _has_error';
					   no_error = false;
					   tooltip = create_tooltip(elem, "Enter a valid email address.");
					 }
				   }
				   if (no_error && /date_field/.test(elem.className)) {
					 if (!value.match(/^\d\d\d\d-\d\d-\d\d$/)) {
					   elem.className = elem.className + ' _has_error';
					   no_error = false;
					   tooltip = create_tooltip(elem, "Enter a valid date.");
					 }
				   }
				   tooltip ? resize_tooltip(tooltip) : false;
				   return no_error;
				 };
				 var needs_validate = function(el) {
				   return el.name == 'email' || el.getAttribute('required') !== null;
				 };
				 var validate_form = function(e) {
				   var err = form_to_submit.querySelector('._form_error'), no_error = true;
				   if (!submitted) {
					 submitted = true;
					 for (var i = 0, len = allInputs.length; i < len; i++) {
					   var input = allInputs[i];
					   if (needs_validate(input)) {
						 if (input.type == 'text') {
						   addEvent(input, 'blur', function() {
							 this.value = this.value.trim();
							 validate_field(this, true);
						   });
						   addEvent(input, 'input', function() {
							 validate_field(this, true);
						   });
						 } else if (input.type == 'radio' || input.type == 'checkbox') {
						   (function(el) {
							 var radios = form_to_submit.elements[el.name];
							 for (var i = 0; i < radios.length; i++) {
							   addEvent(radios[i], 'click', function() {
								 validate_field(el, true);
							   });
							 }
						   })(input);
						 } else if (input.tagName == 'SELECT') {
						   addEvent(input, 'change', function() {
							 validate_field(this, true);
						   });
						 } else if (input.type == 'textarea'){
						   addEvent(input, 'input', function() {
							 validate_field(this, true);
						   });
						 }
					   }
					 }
				   }
				   remove_tooltips();
				   for (var i = 0, len = allInputs.length; i < len; i++) {
					 var elem = allInputs[i];
					 if (needs_validate(elem)) {
					   if (elem.tagName.toLowerCase() !== "select") {
						 elem.value = elem.value.trim();
					   }
					   validate_field(elem) ? true : no_error = false;
					 }
				   }
				   if (!no_error && e) {
					 e.preventDefault();
				   }
				   resize_tooltips();
				   return no_error;
				 };
				 addEvent(window, 'resize', resize_tooltips);
				 addEvent(window, 'scroll', resize_tooltips);
				 window._old_serialize = null;
				 if (typeof serialize !== 'undefined') window._old_serialize = window.serialize;
				 _load_script("//d3rxaij56vjege.cloudfront.net/form-serialize/0.3/serialize.min.js", function() {
				   window._form_serialize = window.serialize;
				   if (window._old_serialize) window.serialize = window._old_serialize;
				 });
				 var form_submit = function(e) {
				   e.preventDefault();
				   if (validate_form()) {
					 // use this trick to get the submit button & disable it using plain javascript
					 document.querySelector('#_form_35_submit').disabled = true;
						   var serialized = _form_serialize(document.getElementById('_form_35_'));
					 var err = form_to_submit.querySelector('._form_error');
					 err ? err.parentNode.removeChild(err) : false;
					 _load_script('https://reena97786.activehosted.com/proc.php?' + serialized + '&jsonp=true');
				   }
				   return false;
				 };
				//  addEvent(form_to_submit, 'submit', form_submit);
			   })();
			   
			   </script>
	`,
			}}
		></div>
	);
};

const EmailSignup = () => (
	<>
		<OneLineForm
			inputWidth={["90%", "100%", "90%"]}
			placeholder="Email Address"
			button={(submitForm) => (
				<CallToAction
					onClick={(e) => {
						e.preventDefault();

						window._load_script = function (url, callback) {
							var head = document.querySelector("head"),
								script = document.createElement("script"),
								r = false;
							script.type = "text/javascript";
							script.charset = "utf-8";
							script.src = url;
							if (callback) {
								script.onload = script.onreadystatechange =
									function () {
										if (
											!r &&
											(!this.readyState ||
												this.readyState == "complete")
										) {
											r = true;
											callback();
										}
									};
							}
							head.appendChild(script);
						};

						window._show_thank_you = function (
							id,
							message,
							trackcmp_url
						) {
							var form = document.getElementById(
									"_form_" + id + "_"
								),
								thank_you =
									form.querySelector("._form-thank-you");
							form.querySelector("._form-content").style.display =
								"none";
							thank_you.innerHTML = message;
							thank_you.style.display = "block";
							if (
								typeof trackcmp_url != "undefined" &&
								trackcmp_url
							) {
								// Site tracking URL to use after inline form submission.
								_load_script(trackcmp_url);
							}
							if (typeof window._form_callback !== "undefined")
								window._form_callback(id);
						};

						window._show_error = function (id, message, html) {
							var form = document.getElementById(
									"_form_" + id + "_"
								),
								thank_you =
									form.querySelector("._form-thank-you");
							form.querySelector("._form-content").style.display =
								"none";
							thank_you.innerHTML = message;

							// button.parentNode.insertBefore(wrapper, button);
							// document.querySelector('[id^="_form"][id$="_submit"]').disabled = false;
							if (html) {
								var div = document.createElement("div");
								div.className = "_error-html";
								div.innerHTML = html;
								err.appendChild(div);
							}
						};

						window._old_serialize = null;

						_load_script(
							"//d3rxaij56vjege.cloudfront.net/form-serialize/0.3/serialize.min.js",
							function () {
								window._form_serialize = window.serialize;

								if (window._old_serialize)
									window.serialize = window._old_serialize;
							}
						);
						function serialize(form) {
							if (!form || form.nodeName !== "FORM") {
								return;
							}
							var i,
								j,
								q = [];
							for (i = 0; i < form.elements.length; i++) {
								if (form.elements[i].name === "") {
									continue;
								}
								switch (form.elements[i].nodeName) {
									case "INPUT":
										switch (form.elements[i].type) {
											case "text":
											case "hidden":
											case "password":
											case "button":
											case "reset":
											case "submit":
												q.push(
													form.elements[i].name +
														"=" +
														encodeURIComponent(
															form.elements[i]
																.value
														)
												);
												break;
											case "checkbox":
											case "radio":
												if (form.elements[i].checked) {
													q.push(
														form.elements[i].name +
															"=" +
															encodeURIComponent(
																form.elements[i]
																	.value
															)
													);
												}
												break;
											case "file":
												break;
										}
										break;
									case "TEXTAREA":
										q.push(
											form.elements[i].name +
												"=" +
												encodeURIComponent(
													form.elements[i].value
												)
										);
										break;
									case "SELECT":
										switch (form.elements[i].type) {
											case "select-one":
												q.push(
													form.elements[i].name +
														"=" +
														encodeURIComponent(
															form.elements[i]
																.value
														)
												);
												break;
											case "select-multiple":
												for (
													j = 0;
													j <
													form.elements[i].options
														.length;
													j++
												) {
													if (
														form.elements[i]
															.options[j].selected
													) {
														q.push(
															form.elements[i]
																.name +
																"=" +
																encodeURIComponent(
																	form
																		.elements[
																		i
																	].options[j]
																		.value
																)
														);
													}
												}
												break;
										}
										break;
									case "BUTTON":
										switch (form.elements[i].type) {
											case "reset":
											case "submit":
											case "button":
												q.push(
													form.elements[i].name +
														"=" +
														encodeURIComponent(
															form.elements[i]
																.value
														)
												);
												break;
										}
										break;
								}
							}
							return q.join("&");
						}
						var serialized = serialize(
							document.getElementById("one-line-form")
						);

						_load_script(
							"https://reena97786.activehosted.com/proc.php?" +
								serialized +
								"&jsonp=true"
						);
					}}
					type="submit"
				/>
			)}
			// handleSubmit={e => {
			// 	// const formData = new FormData(e.target)
			// 	// for (var [key, value] of formData.entries()) {
			// 	// 	console.log(key, value);
			// 	// }
			// 	// fetch(
			// 	// 	"https://reena97786.activehosted.com/proc.php",
			// 	// 	{
			// 	// 		method: "POST",
			// 	// 		body: e.target.data
			// 	// 	}
			// 	// );
			// }}
			inputBackground={colors.mediumblue}
		/>
		{/* Form */}
		<CrazyComponent />
	</>
);

const BasicFooter = () => (
	<React.Fragment>
		<Flex
			flexDirection={["column", "column", "row", "row"]}
			width="100vw"
			css="position: relative;"
		>
			<Flex
				flexDirection="column"
				width={["100%", "100%", "50%", "66.6%"]}
				bg={colors.darkblue}
				pt={[9, 9, 12, 12]}
				pb={[9, 9, 10, 10]}
			>
				<LayoutPadding>
					<Text
						fontSize={[3, 3, 6, 6]}
						color={colors.white}
						mb={[6, 6, 9, 9]}
					>
						{"Thanks for Visiting"}
					</Text>
				</LayoutPadding>
			</Flex>
			<Flex
				flexDirection="column"
				bg={colors.mediumblue}
				width={["100%", "100%", "50%", "33.4%"]}
				pt={[9, 9, 12, 12]}
				pb={[9, 9, 10, 10]}
			>
				<LayoutPadding>
					<Link
						variant="noChange"
						fontSize={[0]}
						href={"/board-of-directors"}
						css={`
							text-transform: uppercase;
							border-bottom: none;
							margin: 10px 0;
							border: none;
							&:hover {
								margin-bottom: 9px;
							}
						`}
						fontWeight="normal"
					>
						Board of Directors
					</Link>
					{/* REMOVED AT THE REQUEST OF CLIENT */}
					{/* <Link
						variant="noChange"
						fontSize={[0]}
						href={"/latest-news"}
						css="text-transform: uppercase; border-bottom: 5px solid transparent; border-top: 5px solid transparent"
						fontWeight="normal"
						mt={[2, 2, 5]}
					>
						Latest News
					</Link> */}
					<Link
						variant="noChange"
						fontSize={[0]}
						href={"/policy"}
						css={`
							text-transform: uppercase;
							border-bottom: none;
							margin: 10px 0;
							border: none;
							&:hover {
								margin-bottom: 9px;
							}
						`}
						fontWeight="normal"
						mt={[2, 2, 5]}
					>
						Privacy Policy
					</Link>
					<Link
						variant="noChange"
						fontSize={[0]}
						href={"http://www.reena.org/"}
						css={`
							text-transform: uppercase;
							border-bottom: none;
							margin: 10px 0;
							border: none;
							&:hover {
								margin-bottom: 9px;
							}
						`}
						fontWeight="normal"
						mt={[2, 2, 5]}
					>
						Reena Group of Charities
					</Link>
				</LayoutPadding>
				<Flex
					width={["35px", "40px"]}
					// mx={[8, 8, 8, 10]}
					// my={["105px", 10, 7, 10]}
					css="
					position: relative;
					bottom: 40px;
					right: 40px;
				"
					alignItems="center"
					justifyContent="center"
				>
					<Button
						name="Scroll to Top"
						width={["35px", "40px"]}
						onClick={() => animateScroll.scrollToTop()}
						bg={colors.lightblue}
						borderRadius="100%"
						width="auto"
					>
						<Image
							alt="Scroll to Top"
							css={`
								transition: 0.3s ease;
								&:hover {
									transform: translateY(-4px);
								}
							`}
							src="/static/icons/misc/chevron.svg"
						/>
					</Button>
				</Flex>
			</Flex>
		</Flex>
	</React.Fragment>
);

const GET_FOOTER = gql`
	query getSocialLinks {
		contactUs {
			edges {
				node {
					socialMediaFacebookUrl
					socialMediaTwitterUrl
					socialMediaYoutubeUrl
					socialMediaLinkedinUrl
					socialMediaInstagramUrl
				}
			}
		}

		footerIndex {
			edges {
				node {
					footerTitle
					footerSubtitle
					ctaTitle
					ctaUrl
				}
			}
		}
	}
`;

export function Footer(props) {
	const { data, loading, error } = useQuery(GET_FOOTER);

	if (error || loading || props.error) return <BasicFooter />;

	const {
		socialMediaFacebookUrl,
		socialMediaTwitterUrl,
		socialMediaYoutubeUrl,
		socialMediaLinkedinUrl,
		socialMediaInstagramUrl,
	} = data.contactUs.edges[0].node;

	const { footerTitle, footerSubtitle, ctaTitle, ctaUrl } =
		data.footerIndex.edges[0].node;

	return (
		<React.Fragment>
			<Flex
				flexDirection={["column", "column", "row", "row"]}
				width="100vw"
				css="position: relative;"
			>
				<Flex
					// flexDirection="column"
					width={["100%", "100%", "50%", "60%"]}
					bg={colors.darkblue}
					pt={[9, 9, 12, 12]}
					pb={[9, 9, 10, 10]}
				>
					<LayoutPadding>
						<Flex
							width="100%"
							flexDirection={[
								"column",
								"column",
								"column",
								"row",
							]}
							alignItems={[
								"flex-start",
								"flex-start",
								"flex-start",
								"center",
							]}
						>
							<Flex flexDirection={["column"]} mr={[0, 0, 8, 10]}>
								<Text
									fontSize={[3, 3, 6, 6]}
									color={colors.white}
									mb={[4]}
								>
									{footerTitle}
								</Text>
								{footerSubtitle && (
									<Text
										fontSize={[2, 2, 4, 4]}
										color={colors.white}
										mb={[6, 6, 6, 0]}
									>
										{footerSubtitle}
									</Text>
								)}
							</Flex>
							<Flex>
								<CallToAction title={ctaTitle} href={ctaUrl} />
							</Flex>
						</Flex>
					</LayoutPadding>
				</Flex>
				<Flex
					flexDirection="column"
					bg={colors.mediumblue}
					width={["100%", "100%", "50%", "40%"]}
					pt={[9, 9, 12, 12]}
					pb={[9, 9, 10, 10]}
				>
					<LayoutPadding>
						<Link
							variant="noChange"
							fontSize={[0]}
							href={"/board-of-directors"}
							css={`
								text-transform: uppercase;
								border-bottom: none;
								margin: 10px 0;
								border: none;
								&:hover {
									margin-bottom: 9px;
								}
							`}
							fontWeight="normal"
						>
							Board of Directors
						</Link>
						{/* REMOVED AT THE REQUEST OF CLIENT */}
						{/* <Link
												variant="noChange"
												fontSize={[0]}
												href={"/latest-news"}
												css="text-transform: uppercase; border-bottom: 5px solid transparent; border-top: 5px solid transparent"
												fontWeight="normal"
												mt={[2, 2, 5]}
											>
												Latest News
											</Link> */}
						<Link
							variant="noChange"
							fontSize={[0]}
							href={"/policy"}
							css={`
								text-transform: uppercase;
								border-bottom: none;
								margin: 10px 0;
								border: none;
								&:hover {
									margin-bottom: 9px;
								}
							`}
							fontWeight="normal"
							mt={[2, 2, 5]}
						>
							Privacy Policy
						</Link>
						<Link
							variant="noChange"
							fontSize={[0]}
							href={"http://www.reena.org/"}
							css={`
								text-transform: uppercase;
								border-bottom: none;
								margin: 10px 0;
								border: none;
								&:hover {
									margin-bottom: 9px;
								}
							`}
							fontWeight="normal"
							mt={[2, 2, 5]}
						>
							Reena Group of Charities
						</Link>
						<Flex
							alignItems={[
								"flex-start",
								"center",
								"center",
								"center",
							]}
							flexDirection={["column", "row", "row", "row"]}
							mt={[7, 7, 10, 8]}
							pt={[3]}
							mb={[0, 6, 8, 10]}
						>
							<Text
								fontSize={[1, 3, 3, 3]}
								color={colors.white}
								fontWeight="bold"
								css="text-transform: uppercase; min-width: 125px;"
								mr={3}
								width={["100%", "auto", "auto", "100%"]}
							>
								Follow Us:
							</Text>
							<Flex
								width={["50%", "40%", "100%", "100%"]}
								justifyContent={["space-between"]}
								mt={[6, "0px", "0px", "0px"]}
								alignSelf={["flex-start", "flex-end"]}
								px={["0px", 3, 3, 3]}
								css={`
									a {
										border: none;
									}
								`}
							>
								<Flex
									width={["24px", "24px", "20px", "18px"]}
									mr={4}
									alignItems={"center"}
									css={`
										cursor: pointer;
									`}
								>
									<Link
										variant="noChange"
										href={socialMediaFacebookUrl}
										name="Facebook"
									>
										<Image
											alt="Facebook"
											src="/static/icons/social/facebook.svg"
										/>
									</Link>
								</Flex>
								<Flex
									width={["24px", "24px", "20px", "18px"]}
									mr={4}
									alignItems={"center"}
									css={`
										cursor: pointer;
									`}
								>
									<Link
										variant="noChange"
										href={socialMediaTwitterUrl}
										name="Twitter"
									>
										<Image
											alt="Twitter"
											src="/static/icons/social/twitter.svg"
										/>
									</Link>
								</Flex>
								<Flex
									width={["24px", "24px", "20px", "18px"]}
									mr={4}
									alignItems={"center"}
									css={`
										cursor: pointer;
									`}
								>
									<Link
										variant="noChange"
										href={socialMediaInstagramUrl}
										name="Instagram"
									>
										<Image
											alt="Instagram"
											src="/static/icons/social/instagram.svg"
										/>
									</Link>
								</Flex>
								<Flex
									width={["24px", "24px", "20px", "18px"]}
									mr={4}
									alignItems={"center"}
									css={`
										cursor: pointer;
									`}
								>
									<Link
										variant="noChange"
										href={socialMediaYoutubeUrl}
										name="Youtube"
									>
										<Image
											alt="Youtube"
											src="/static/icons/social/youtube.svg"
										/>
									</Link>
								</Flex>
								<Flex
									width={["24px", "24px", "20px", "18px"]}
									alignItems={"center"}
									css={`
										cursor: pointer;
									`}
								>
									<Link
										variant="noChange"
										href={socialMediaLinkedinUrl}
										name="Linkedin"
									>
										<Image
											alt="linkedin"
											src="/static/icons/social/linkedin.svg"
										/>
									</Link>
								</Flex>
							</Flex>
						</Flex>
						<Flex
							alignItems={["flex-start", "flex-end"]}
							justifyContent="center"
							flexDirection="column"
							color="white"
							mt={6}
						>
							<Text mb="10px" fontWeight="bold">
								REGISTRATION
							</Text>
							<Text>NO. 897952719RR0001</Text>
						</Flex>
					</LayoutPadding>
				</Flex>
			</Flex>
		</React.Fragment>
	);
}

export default Footer;
