import { Flex } from 'rebass';

export const ListView = ({children, autoFillWidth, columns, gap, columnsMobile, gapMobile, columnsTablet, gapTablet}) => (
    <Flex
        width={'100%'}
        pb={[7,7,9]}
        css={`
            display: grid;
            grid-template-columns: repeat(${autoFillWidth ? `auto-fill, minmax(${autoFillWidth}, 1fr))` : columns ? `${columns}, 1fr)` : null};
            grid-gap: ${gap ? gap : 2.5}rem;

            @media only screen and (max-width: 1055px){
                grid-template-columns: repeat(${columnsTablet ? columnsTablet : 2}, 1fr);
                grid-gap: ${gapTablet ? gapTablet : 1.5}rem;
            }

            @media only screen and (max-width: 576px) {
                grid-template-columns: repeat(${columnsMobile ? columnsMobile : 2}, 1fr);
                grid-gap: ${gapMobile ? gapMobile : 1.5}rem;
            }
        `}
    >
        {children}
    </Flex>
)