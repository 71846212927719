// Libraries
import { Flex, Image } from "rebass";
import ReactPlayer from "react-player";
// Component Imports
import { colors } from "../../styleguide/theme";

export class Video extends React.Component {
	static defaultProps = {
		src: "/static/test-images/coverr-grand-central-station.mp4",
	};

	constructor(props) {
		super(props);

		this.state = {
			play: false,
		};
	}

	handlePlay = () => {
		this.setState({ play: true });
	};

	render() {
		const { src, small } = this.props;
		return small ? (
			<Flex
				width={["100%", "100%", "100%", "100%"]}
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
			>
				<Flex width={["100%"]}>
					<div
						style={{
							position: "relative",
							paddingBottom: "56.25%" /* 16:9 Aspect Ratio */,
							width: "100%",
						}}
					>
						{/* PLAY BUTTON OVERLAY */}
						<Flex
							onClick={this.handlePlay}
							width={"100%"}
							justifyContent={"center"}
							alignItems={"center"}
							css={`
								position: absolute;
								height: 100%;
								/* REMOVE OVERLAY WHEN PLAYING */
								z-index: ${this.state.play ? 0 : 1};
								background-color: ${colors.videoOverlay};
							`}
						>
							<Image
								src="/static/icons/misc/Play-button.svg"
								width={"11%"}
								alt="play"
								css={`
									opacity: 1;
								`}
							/>
						</Flex>
						<ReactPlayer
							playing={this.state.play}
							onPause={() => this.setState({ play: false })}
							width={"100%"}
							height={"100%"}
							style={{
								position: "absolute",
								top: 0,
								left: 0,
							}}
							controls
							url={src}
						/>
					</div>
				</Flex>
			</Flex>
		) : (
			<Flex
				width={["100%", "100%", "100%", "100%"]}
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				bg={colors.offwhite}
			>
				<Flex width={["100%", "100%", "100%", "90%"]}>
					<div
						style={{
							position: "relative",
							paddingBottom: "56.25%" /* 16:9 Aspect Ratio */,
							width: "100%",
						}}
					>
						{/* PLAY BUTTON OVERLAY */}
						<Flex
							onClick={this.handlePlay}
							width={"100%"}
							justifyContent={"center"}
							alignItems={"center"}
							css={`
								position: absolute;
								height: 100%;
								z-index: ${this.state.play ? 0 : 1};
								background-color: ${colors.videoOverlay};
							`}
						>
							<Image
								src="/static/icons/misc/Play-button.svg"
								width={"11%"}
								alt="play"
								css={`
									opacity: 1;
								`}
							/>
						</Flex>
						<ReactPlayer
							playing={this.state.play}
							onPause={() => this.setState({ play: false })}
							width={"100%"}
							height={"100%"}
							style={{
								position: "absolute",
								top: 0,
								left: 0,
							}}
							controls
							url={src}
						/>
					</div>
				</Flex>
			</Flex>
		);
	}
}
