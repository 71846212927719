import { Image, Flex, Text } from "rebass";
import { default as NextBase } from "next/link";

import { colors } from "../../styleguide/theme";
import { Link } from "../links";

export const DetailCard = ({ image, title, date, location, color, href }) => {
	return (
		<NextBase href={href}>
			<Flex
				flexDirection={"column"}
				color={color ? color : colors.white}
				ml={"0"}
				mb={[2, 2, 4, 4]}
				width={"100%"}
				tabIndex={0}
				css={`
					cursor: pointer;

					&:hover .detail-card--hover {
						box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
							0 10px 10px rgba(0, 0, 0, 0.22);
					}

					&:hover .card__content--text {
						display: flex;
					}

					&:active: {
						outline: 1px solid black;
						background: red;
					}

					@media only screen and (min-width: 1055px) {
						// height: 25rem;
						max-width: 375px;
					}
				`}
			>
				<Flex
					className={"detail-card--hover"}
					alignItems={"flex-end"}
					width={"100%"}
					p={6}
					css={`
						background-image: url("${image}");
						background-position: center;
						background-size: cover;
						background-repeat: no-repeat;
						height: 40vw;

						@media only screen and (min-width: 1055px) {
							height: 28vw;
							max-height: 375px;
						}
					`}
				>
					<Flex
						className={"card__content--text"}
						color={colors.white}
						css={`
							display: none;
						`}
					>
						<Flex mr={[4, 4, 5, 5]}>
							<Image
								width={"35px"}
								src={"/static/icons/misc/Arrow-Left.svg"}
							/>
						</Flex>
						<Text
							as="h5"
							css={`
								text-transform: uppercase;
								text-shadow: 0px 2px 2px ${colors.lightblue};
							`}
						>
							learn more
						</Text>
					</Flex>
				</Flex>
				{title && (
					<Text
						as="h5"
						my={4}
						fontSize={[2, 2, 3, 3]}
						css={`
							text-transform: uppercase;
						`}
					>
						{title}
					</Text>
				)}
				{date && (
					<Text
						as="p"
						fontWeight={"100"}
						css={`
							line-height: 20px;
						`}
					>
						{date}
					</Text>
				)}
				{location && (
					<Text
						as="p"
						fontWeight={500}
						css={`
							font-style: italic;
							line-height: 20px;
						`}
					>
						{location}
					</Text>
				)}
			</Flex>
		</NextBase>
	);
};
