import { Box, Flex, Text, Image, Link } from "rebass";
import React from "react";
import Slider from "react-slick";
import { colors } from "../../styleguide/theme";

const Caption = ({ children, ...rest }) => (
	<Text as="figcaption" fontSize={[0]} color={colors.black} mt={2} mb={6}>
		{children}
	</Text>
);

const SliderBtn = ({ onClick, type }) => (
	<Link
		className="slick-arrow"
		href={type === "left" ? "#previous" : "#next"} // Accessibility
		name={type === "left" ? "previous" : "next"} // Accessibility
		role="button" // Accessibility
		bg={colors.green}
		width={["40px"]}
		justifyContent={"center"}
		onClick={onClick}
		css={`
			height: 10vw;
			position: absolute;
			z-index: 10;
			${type}: 0;
			top: 0;
		`}
	>
		<Image
			width={"2rem"}
			src={"/static/icons/misc/chevron.svg"}
			css={`
				transform: rotate(${type === "left" ? -90 : 90}deg);
			`}
		/>
	</Link>
);

export class ImageSlider extends React.Component {
	static defaultProps = {
		items: [],
	};

	state = {
		slick1: null,
		slick2: null,
		current: 1,
	};

	componentDidMount() {
		this.setState({
			slick1: this.slider1,
			slick2: this.slider2,
		});
	}

	render() {
		const { current, slick1, slick2 } = this.state;
		const { items } = this.props;

		const slick1Settings = {
			asNavFor: slick2,
			ref: (slider) => (this.slider1 = slider),
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			afterChange: (index) => this.setState({ current: index + 1 }), //set current image based on slide index.
		};

		const slick2settings = {
			asNavFor: slick1,
			ref: (slider) => (this.slider2 = slider),
			slidesToShow: items.length < 5 ? items.length : 5,
			swipeToSlide: true,
			focusOnSelect: true,
			centerMode: true,
			nextArrow: <SliderBtn type="right" />,
			prevArrow: <SliderBtn type="left" />,
		};

		return (
			<Box width={"100%"}>
				<Flex width={"100%"} justifyContent={"flex-end"}>
					<Text
						mb={2}
						color={colors.black}
						fontSize={0}
						fontWeight={600}
					>{`${current}/${items.length || 0}`}</Text>
				</Flex>
				<Slider {...slick1Settings}>
					{items.map((img, i) => (
						<Flex
							width={"100%"}
							css={`
								height: 40vw;

								@media only screen and (max-width: 1055px) {
									height: 450px;
								}

								@media only screen and (max-width: 768px) {
									height: 375px;
								}

								@media only screen and (max-width: 550px) {
									height: 250px;
								}
							`}
						>
							<Flex
								width={"100%"}
								css={`
									height: 100%;
									background-image: url("${img.url}");
									background-position: center;
									background-size: contain;
									background-repeat: no-repeat;
								`}
							></Flex>
							{/* <Image src={img.url} width={"100%"}/> */}
						</Flex>
					))}
				</Slider>

				{/* IMAGE CAPTION  */}
				<Caption>
					{items[current - 1].description || `Image #${current}`}
				</Caption>

				{/* SMALLER NAV SLIDER */}
				<Slider {...slick2settings}>
					{items.map((img, i) => (
						<Flex
							width={"100%"}
							css={`
								height: 10vw;
								position: relative;
							`}
						>
							{/* OVERLAY  */}
							<Flex
								width={"100%"}
								css={`
									height: 100%;
									background-color: rgba(
										0,
										0,
										0,
										${i + 1 === current ? 0 : 0.55}
									);
									z-index: 1;
									position: absolute;
									top: 0;
									left: 0;
								`}
							/>
							<Flex
								width={"100%"}
								css={`
									height: 100%;
									background-image: url("${img.url}");
									background-position: center;
									background-size: cover;
									background-repeat: no-repeat;
								`}
							></Flex>
						</Flex>
					))}
				</Slider>
			</Box>
		);
	}
}
