// Libraries
import React, { Component } from "react";
import { default as NextBase } from "next/link";

// Component Imports
import { Hide } from "../utilities";
import { HoverOverlay } from "../cards/HoverOverlay";
import { colors } from "../../styleguide/theme";
import { Flex, Text, Image } from "rebass";

export const StoryCard = ({ title, description, href, width, name, image }) => {
	return (
		<>
			{/* DESKTOP VERSION */}
			<Hide
				width={width ? width : ["50%", "50%", "30%"]}
				hide={[0, 0, 1]}
			>
				<NextBase passHref href={href}>
					<HoverOverlay
						fullHover
						title={title}
						image={image}
						height={"30vw"}
						width={["100%"]}
					>
						<Flex flexDirection={["column"]} pb={[4, 4, 5, 5]}>
							<Text as="p">{description}</Text>
							{/* BUTTON */}
							<Flex mt={[4, 4, 5, 5]}>
								<Flex mr={[4, 4, 5, 5]}>
									<Image
										width={"35px"}
										src={
											"/static/icons/misc/Arrow-Left-Blue.svg"
										}
									/>
								</Flex>
								<Text
									className={"card__content--text-shadow"}
									as="h5"
									css={`
										text-transform: uppercase;
									`}
								>
									learn more
								</Text>
							</Flex>
						</Flex>
					</HoverOverlay>
				</NextBase>
			</Hide>

			{/* MOBILE VERSION */}
			<Hide width={"100%"} hide={[1, 1, 0]}>
				<Flex
					flexDirection={"column"}
					color={colors.darkblack}
					width={"100%"}
					css={`
						min-height: 130px;
						overflow: hidden;

						& a {
							text-decoration: none;
						}
					`}
				>
					<Image src={image} width={"100%"} />
					<Text
						as="h5"
						fontSize={[2]}
						mt={[5]}
						css={`
							line-height: ${1};
							text-transform: uppercase;
							letter-spacing: 1.8px;
							@media only screen and (max-width: 768px) {
								letter-spacing: 0px;
							}
						`}
					>
						{title}
					</Text>
					<Text
						as="p"
						fontSize={1}
						fontWeight={100}
						mt={1}
						css={`
							line-height: 22px;
						`}
					>
						{description}
					</Text>
					<NextBase passHref href={href}>
						<Text
							mt={[4]}
							color={colors.lightblue}
							css={`
								text-transform: uppercase;
								line-height: 22px;
								text-decoration: underline;

								&:hover {
									cursor: pointer;
								}
							`}
						>
							Learn More
						</Text>
					</NextBase>
				</Flex>
			</Hide>
		</>
	);
};
