import {Paragraph} from '../info'

export const ParagraphSection = ({
	content,
	quote,
	nextPage,
	previousPage,
	showNavigation
}) => {
	return (
		<Paragraph
			showNavigation={showNavigation}
			body={content}
			quote={quote}
			nextHref={{
				pathname: `/campaigns/campaign/${
					nextPage ? nextPage.jumbotronTitle.split(" ").join("-") : ""
				}`,
				query: { id: nextPage ? nextPage.id : "" }
			}}
			previousHref={{
				pathname: `/campaigns/campaign/${
					previousPage
						? previousPage.jumbotronTitle.split(" ").join("-")
						: ""
				}`,
				query: { id: previousPage ? previousPage.id : "" }
			}}
		/>
	);
};
