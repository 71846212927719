export const colors = {
    darkblack: "#333333",
    black: "#4a4a4a",
    lightyellow: "#FFE472",
    deepyellow: "#F4C916",
    yellow: "#FEB300",
    white: "#ffffff",
    offwhite: "#F7F9FA",
    pink: "#eb1575",
    pressedPink: "#ec0d71",
    lightPink: "#fde9f2",
    purple: '#49175C',
    red: "#d0021b",
    errorRed: "#E80036",
    errorPink: "#E800360A",
    darkGrey: "#E3E3E3",
    subtleGrey: "#e9e9e9",
    subtleGray: "#F1F1F1",
    grey: "#cbcbcb",
    lightGrey: "rgb(249, 249, 249)",
    green: "#006A74",
    darkgreen: "#005B64",
    darkblue: '#004172',
    mediumblue: '#0C5083',
    lightblue: '#008EFA',
    mediumblue2: '#0080E2',
    hoverGradient: 'rgba(72,23,92,0.8)',
    videoOverlay: 'rgba(51,51,51,0.8)'
};

export const fontSizes = ['0.875rem', '1rem', '1.125rem', '1.25rem', '1.5rem', '1.5625rem', '1.875rem', '2.25rem', '2.5rem', '3.375rem', '3.75rem', '4rem']

export const layoutConfig = {
    paddingX: ['2rem','2rem','4rem'],
    paddingY: ['2.875rem']
}

export default {
    // Breakpoints based on Bootstrap 4 sizes
    breakpoints: ["576px", "768px", "1055px", "1200px"],
    fontSizes: fontSizes,
    fontWeights: ["normal", "bold"],
    colors,
    space: [0, '.25rem', '0.375rem', '0.5rem', '0.875rem', '1rem', '1.375rem', '1.875rem', '2rem', '2.875rem', '3.25rem', '4rem', '5.125rem', '8rem'],
    fonts: {
        sans: "Raleway, sans-serif",
        mono: "Menlo, monospace"
    },
    links: {
        primary: {
            color: colors.darkblack,
            hover: {
                color:colors.darkblack,
                fontWeight: 'bold',
                borderBottom: "5px solid #FFE472",
            },
            visited: colors.Pink,
        },
        secondary: {
            color: colors.black,
            hover: colors.pink,
            visited: colors.black,
        },
        noChange: {
            color: colors.white,
            hover: '',
            visited: '',
        },
        mobileHide: {
            color: colors.darkblack,
            hover: {
                color:colors.darkblack,
                fontWeight: 'bold',
                borderBottom: "5px solid transparent",
            },
            visited: colors.Pink,
        },
    },
    buttons: {
        primary: {
            backgroundColor: colors.lightyellow,
            color: colors.darkblack,
        },
        secondary: {
            backgroundColor: colors.white,
            color: colors.black
        },
        primaryDisabled: {
            backgroundColor: colors.lightPink,
            color: colors.white
        },
        secondaryDisabled: {
            backgroundColor: colors.white,
            color: colors.lightPink
        },
        donate: {
            backgroundColor: colors.lightyellow,
            color: colors.darkblack
        },
        hidden: {
            backgroundColor: colors.lightyellow,
            color: colors.darkblack
        },
        transparent: {
            backgroundColor: "transparent",
            color: colors.darkblack
        },
        donationFlow: {
            backgroundColor: colors.lightblue,
            color: colors.white,
            borderRadius: '0px',
            fontSize: fontSizes[0],
            "&:hover": {
                // backgroundColor: colors.mediumblue,
                // color: colors.white,
            },
        }
    },
    cards: {
        standard: {
            backgroundImage: `linear-gradient(
                324deg,
                #eb1575,
                #fa127a 28%,
                #fe3d93 62%,
                #ff78b5
                )`,
            boxShadow: `2px 1px 6px 2px rgba(187, 187, 187, 0.5)`,
            transition: `.25s ease`,
            borderRadius: '10px',
            "&:hover": {
                boxShadow: `4px 16px 20px 7px rgba(187, 187, 187, 0.5);`,

              },
        }
    }
};
